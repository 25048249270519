import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { InputGroup, Form } from 'react-bootstrap';

import { ReactComponent as SearchIcon } from 'assets/images/search.svg';
import { ReactComponent as CrossIcon } from 'assets/images/cross-icon.svg';
import './styles.scss';

const BudgetSearchInput = React.forwardRef(
  ({ placeholder, handleClearSearch, searchVal, ...rest }, ref) => {
    return (
      <InputGroup className="search-input-wrapper">
        <InputGroup.Text className="search-icon">
          <SearchIcon />
        </InputGroup.Text>
        <Form.Control
          aria-label={placeholder}
          className="search-input"
          placeholder={placeholder}
          value={searchVal}
          {...rest}
        />
        {searchVal && (
          <CrossIcon className="close-icon" onClick={handleClearSearch} />
        )}
      </InputGroup>
    );
  }
);

BudgetSearchInput.displayName = 'BudgetSearchInput';

BudgetSearchInput.propTypes = {
  placeholder: PropTypes.string,
  handleClearSearch: PropTypes.func,
  searchVal: PropTypes.string,
};

BudgetSearchInput.defaultProps = {
  placeholder: 'Search budgets...',
  handleClearSearch: () => null,
  searchVal: '',
};

export default BudgetSearchInput;
