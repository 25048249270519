import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { Form } from 'react-bootstrap';
import { toast } from 'react-toastify';

import SocialLogin from 'components/SocialLogin';
import FormInput from 'components/FormInput';
import FormCheckbox from 'components/FormCheckbox';
import Button from 'components/Button';
import FormPasswordInput from 'components/FormPasswordInput';
import ALL_ROUTES from 'config/routes';
import { useAuthContext } from 'layouts/AuthLayout/contexts/AuthContext';
import { handleLoginSuccess, handleRememberMeSetting } from 'utils/common';

import './styles.scss';
import { toastStyle } from 'utils/helper';

const Login = () => {
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
  } = useForm({
    defaultValues: {
      email: localStorage.getItem('email'),
      password: localStorage.getItem('password'),
      remember_me: localStorage.getItem('remember_me'),
    },
  });

  const { loginMutation, handleSocialLogin } = useAuthContext();

  const handleLoginFormSubmit = () =>
    handleSubmit(async data => {
      try {
        // toast.loading('Please wait while we sign in to your account...');
        const response = await loginMutation.mutateAsync({
          username: data?.email,
          password: data?.password,
        });
        if (response?.data?.token) {
          handleRememberMeSetting(data?.remember_me, data?.email);
          const userData = {
            ...(response?.data?.user || {}),
            social_signup: response?.data?.social_signup,
          };
          handleLoginSuccess(response?.data?.token, userData, navigate);
        }
        toast.dismiss();
      } catch (error) {
        const errorMessage =
          error?.response?.data?.non_field_errors?.[0] ||
          error?.response?.data?.message?.non_field_errors?.[0] ||
          error?.response?.data?.error;
        toast.dismiss();
        toast.error(
          errorMessage ||
            'An error occurred while sign in. Please try again later',
          toastStyle
        );
      }
    });

  const onLoginSuccess = (provider, data) => {
    // toast.loading('Please wait while we sign in to your account...');
    handleSocialLogin(
      provider,
      data,
      response => {
        if (response?.data?.token) {
          const userData = {
            ...(response?.data?.user || {}),
            social_signup: response?.data?.social_signup,
          };
          handleRememberMeSetting(watch('remember_me'), userData?.email);
          handleLoginSuccess(response?.data?.token, userData, navigate);
        }
        toast.dismiss();
      },
      error => {
        const message =
          error?.response?.data?.email?.[0] ||
          error?.response?.data?.message?.non_field_errors?.[0] ||
          error?.response?.data?.non_field_errors?.[0];
        toast.dismiss();
        toast.error(
          message || 'An error occurred while sign in. Please try again later',
          toastStyle
        );
      }
    );
  };

  const onLoginError = (provider, error) => {
    toast.error(
      'An error occurred while sign in. Please try again later.',
      toastStyle
    );
  };

  return (
    <Form className="sign-in-form" onSubmit={handleLoginFormSubmit()}>
      <h1 className="header">Sign In</h1>
      <FormInput
        label="Email"
        type="email"
        placeholder="Please enter your registered email"
        error={errors?.email?.message}
        {...register('email', { required: 'Email is required field' })}
      />
      <FormPasswordInput
        label="Password"
        placeholder="Please enter your password"
        error={errors?.password?.message}
        {...register('password', { required: 'Password is required' })}
      />
      <div className="d-flex justify-content-between align-items-center">
        <FormCheckbox
          label="Remember Me/Keep Me Signed In"
          labelClassName="remember-me-label"
          {...register('remember_me', { required: false })}
        />
        <Link
          to={ALL_ROUTES.AUTH_CHILDREN.FORGOT_PASSWORD}
          className="forgot-password"
        >
          Forgot Password?
        </Link>
      </div>
      <Button
        label="Sign In"
        className="sign-in-form__sign-in-button"
        type="submit"
      />
      <SocialLogin
        type="Sign In"
        onLoginError={onLoginError}
        onLoginSuccess={onLoginSuccess}
      />
      <div className="sign-in-form__have-account">
        Don&#39;t have an account?{' '}
        <Link className="sign-up-link" to={ALL_ROUTES.AUTH_CHILDREN.SIGNUP}>
          Sign Up
        </Link>
      </div>
    </Form>
  );
};

export default Login;
