import React from 'react';

import './styles.scss';
import Backdrop from 'components/Backdrop';

const GridLoader = () => {
  return (
    <Backdrop>
      <span className="grid-loader">
        <span className="grid-loader__block grid-loader__block-1"></span>
        <span className="grid-loader__block grid-loader__block-2"></span>
        <span className="grid-loader__block grid-loader__block-3"></span>
        <span className="grid-loader__block grid-loader__block-4"></span>
        <span className="grid-loader__block grid-loader__block-5"></span>
        <span className="grid-loader__block grid-loader__block-6"></span>
        <span className="grid-loader__block grid-loader__block-7"></span>
        <span className="grid-loader__block grid-loader__block-8"></span>
        <span className="grid-loader__block grid-loader__block-9"></span>
      </span>
    </Backdrop>
  );
};

export default GridLoader;
