import moment from 'moment-timezone';
import currencyToSymbolMap from 'currency-symbol-map/map';
import { toast } from 'react-toastify';

export const isArray = value => {
  return Array.isArray(value);
};

/**
 *
 * @param {function} fn - function to be called after the delay
 * @param {Number} ms   - delay in milliseconds
 * @returns
 */
export const debounce = (fn, ms = 0) => {
  let timeoutId = null;

  return (...args) => {
    if (timeoutId) {
      clearTimeout(timeoutId);
    }

    timeoutId = setTimeout(() => fn.apply(this, args), ms);
  };
};

export const formatBytes = (bytes, decimals) => {
  const SIZES = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

  let i = 0;
  let r;
  let b;
  for (i = 0, r = bytes, b = 1024; r > b; i++) {
    r /= b;
  }

  return `${parseFloat(r.toFixed(decimals))} ${SIZES[i]}`;
};

export const stopPropagation = event => {
  event?.stopPropagation();
};

// function to delete cookies
export const deleteCookies = () => {
  const Cookies = document.cookie.split(';');

  // set 1 Jan, 1970 expiry for every cookies
  for (let i = 0; i < Cookies.length; i++)
    document.cookie = Cookies[i] + '=;expires=' + new Date(0).toUTCString();
};

export const formatCurrency = (value, currency) => {
  value = fixNumber(value, true);

  currency ||= 'USD';
  return new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: currency,
  })
    .format(Number.isNaN(value) ? 0 : value)
    ?.replace(currency, getCurrencySymbol(currency));
};

// export const formatNumber = (value, currency) => {
//   currency ||= 'USD';
//   const num = new Intl.NumberFormat('en-US', {
//     style: 'currency',
//     currency: currency,
//   })
//     .formatToParts(value)
//     ?.slice(value < 0 ? 2 : 1)
//     ?.map(part => part.value)
//     ?.join('');

//   return value < 0 ? '-' + num : num;
// };
export const formatNumber = (value, currency) => {
  currency ||= 'USD';
  const formatter = new Intl.NumberFormat('en-US', {
    style: 'decimal',
    currency: currency,
    minimumFractionDigits: 0,
    maximumFractionDigits: 4,
  });

  const parts = formatter.formatToParts(value);
  let result = '';

  for (const part of parts) {
    if (part.type === 'decimal') {
      result += '.';
    } else {
      result += part.value;
    }
  }

  // Remove trailing zeros after the decimal point
  result = result.replace(/(\.[0-9]*[1-9])0+$/, '$1').replace(/\.$/, '');
  return result;
};

export const formatDateTime = (
  dateTimeInUTC,
  format = 'hh:mm A (z) dddd, MMMM Do, YYYY'
) => {
  if (!dateTimeInUTC) return '';

  const date = moment.utc(dateTimeInUTC).tz(moment.tz.guess());

  return date.format(format);
};

export const formatBudgetScreenDateTime = (
  dateTimeInUTC,
  format = 'dddd, MMMM Do, YYYY @ hh:mm A (z)'
) => {
  if (!dateTimeInUTC) return '';

  const date = moment.utc(dateTimeInUTC).tz(moment.tz.guess());

  return date.format(format);
};

export const intervalToDuration = (startDate, endDate) => {
  const now = moment.utc(startDate);
  const end = moment.utc(endDate);

  const duration = moment.duration(end.diff(now));

  //Get Days and subtract from duration
  const days = duration.days();
  duration.subtract(days, 'days');

  //Get hours and subtract from duration
  const hours = duration.hours();
  duration.subtract(hours, 'hours');

  //Get Minutes and subtract from duration
  const minutes = duration.minutes();
  duration.subtract(minutes, 'minutes');

  const interval = [];
  days && interval.push(`${days}  Day${days > 1 ? 's' : ''}`);
  hours && interval.push(`${hours} Hour${hours > 1 ? 's' : ''}`);
  minutes && interval.push(`${minutes} Min${minutes > 1 ? 's' : ''}`);

  return interval.join(', ');
};

export const getCurrencySymbol = currencyCode => {
  return currencyToSymbolMap[currencyCode] || '$';
};

/**
 * Utility to download file from blob API response
 *
 * @param {Blob}    data        blob data from API response
 * @param {Object}  config      configuration for the file
 */
export const downloadFile = (data, config = {}) => {
  const { contentTypeHeader, fileName } = config;
  const downloadLink = window.document.createElement('a');

  downloadLink.href = URL.createObjectURL(
    new Blob([data], { type: contentTypeHeader })
  );
  downloadLink.download = fileName;
  document.body.appendChild(downloadLink);
  downloadLink.click();
  document.body.removeChild(downloadLink);
};

export const sortArray = (data, key, sortingOrder = 1) => {
  return data?.sort((a, b) => {
    const firstValue =
      typeof a[key] === 'string' ? a[key]?.toLowerCase() : a[key];
    const secondValue =
      typeof b[key] === 'string' ? b[key]?.toLowerCase() : b[key];

    if (firstValue < secondValue) {
      return sortingOrder * -1;
    }
    if (firstValue > secondValue) {
      return sortingOrder * 1;
    }
    return 0;
  });
};

// export const fixNumber = (number, allowNegative = true) => {
//   if (allowNegative) {
//     if (number) {
//       const isNegative = /^-/.test(number);
//       return (isNegative ? '-' : '') + number.toString().replace(/[^\d.]/g, '');
//     }
//     return number;
//   } else {
//     return number ? number.toString().replace(/[^\d.]/g, '') : number;
//   }
// };

// export const covertTo2Digit = number => {
//   return parseFloat(number)?.toFixed(2)?.toString() || '1';
// };

export const fixNumber = (number, allowNegative = true) => {
  if (allowNegative) {
    if (number) {
      const isNegative = /^-/.test(number);
      let filteredInput = number.toString().replace(/[^\d.]/g, '');
      const parts = filteredInput.split('.');
      // If there's a decimal part, truncate it to 4 digits
      if (parts.length > 1) {
        parts[1] = parts[1].substring(0, 4);
        filteredInput = parts[0] + '.' + parts[1];
      }
      return (isNegative ? '-' : '') + filteredInput;
    }
    return number;
  } else {
    if (!number) {
      return number;
    }
    let filteredInput = number.toString().replace(/[^\d.]/g, '');
    const parts = filteredInput.split('.');
    console.log('parts', parts);
    // If there's a decimal part, truncate it to 4 digits
    if (parts.length > 1) {
      parts[1] = parts[1].substring(0, 4);
      filteredInput = parts[0] + '.' + parts[1];
      console.log('filteredInput', filteredInput);
    }
    return filteredInput;
  }
};

export const covertTo4Digit = number => {
  console.log('number', number);
  const num = number;
  const str = num.toString();
  if (str.includes('.')) {
    const decimalLength = str.split('.')[1].length;
    console.log(`Decimal length: ${decimalLength}`);
    if (decimalLength > 4) {
      return parseFloat(number)?.toFixed(4)?.toString() || '1';
    }
  } else {
    console.log('No decimal part.');
    // return number;
  }
  return number;
  // return parseFloat(number)?.toFixed(4)?.toString() || '1';
};

export const splitArray = (array, chunkSize = 22) => {
  if (!array?.length) return [];
  else
    return [array?.splice(0, chunkSize)]?.concat(splitArray(array, chunkSize));
};
export const indexToAlpha = (num = 1) => {
  // ASCII value of first character
  const A = 'A'.charCodeAt(0);
  const numberToCharacter = number => {
    return String.fromCharCode(A + number);
  };
  return numberToCharacter(num);
};

const collectStyles = element => {
  const win = document.defaultView || window;

  // String letiable to hold styling for each element
  const elementStyle = [];

  // Loop over computed styles
  const styles = win.getComputedStyle(element, null);

  for (let key = 0; key < styles.length; key++) {
    // Check if style should be processed

    if (styles.getPropertyValue(styles[key]))
      elementStyle.push(
        styles[key] + ':' + styles.getPropertyValue(styles[key]) + ';'
      );
  }
  return elementStyle;
};

const setStyle = theElement => {
  const win = document.defaultView || window;
  const defaultElem = document.createElement(theElement.nodeName);
  const child = document.body.appendChild(defaultElem);
  const defaultsStyles = win.getComputedStyle(defaultElem, null);
  const maxj = defaultsStyles.length;
  const computed = collectStyles(theElement);
  const newComputed = [];

  for (let j = 0; j < maxj; j++) {
    const defaultStyle =
      defaultsStyles[j] +
      ':' +
      defaultsStyles.getPropertyValue(defaultsStyles[j]) +
      ';';

    if (!computed.includes(defaultStyle)) {
      newComputed.push(defaultStyle);
    }
  }

  child.remove();

  return newComputed.join('');
};

export const cloneElement = element => {
  // Clone the main node (if not already inside the recursion process)
  const clone = element.cloneNode();

  // Loop over and process the children elements / nodes (including text nodes)
  const childNodesArray = Array.prototype.slice.call(element.childNodes);
  for (let i = 0; i < childNodesArray.length; i++) {
    // Clone the child element
    const clonedChild = cloneElement(childNodesArray[i]);
    //Attach the cloned child to the cloned parent node
    clone.appendChild(clonedChild);
  }

  // Get all styling for print element (for nodes of type element only)
  if (element.nodeType === 1) {
    clone.setAttribute('style', setStyle(element));
  }

  return clone;
};

export const toastStyle = {
  position: toast.POSITION.TOP_RIGHT,
  autoClose: 4000,
  closeButton: false,
  hideProgressBar: true,
  // style: customToastStyle,
};

export const baseUrl =
  window.location.hostname === 'localhost'
    ? 'http://localhost:3000'
    : `https://${window.location.host}`;
