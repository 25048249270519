import React from 'react';
import PropTypes from 'prop-types';
import { Form } from 'react-bootstrap';

import { ReactComponent as ErrorIcon } from 'assets/images/error-outline.svg';
import './styles.scss';

const FormCheckbox = React.forwardRef(
  (
    { error, label, required, inputClassName, labelClassName, ...rest },
    ref
  ) => {
    return (
      <Form.Group className="checkbox-wrapper">
        <Form.Check>
          <Form.Check.Input
            className={`${inputClassName}`}
            type="checkbox"
            isInvalid={Boolean(error)}
            required={required}
            ref={ref}
            {...rest}
          />
          <Form.Check.Label className={`${labelClassName}`}>
            {label}
          </Form.Check.Label>
          <Form.Control.Feedback type="invalid">
            <span className="error-icon">
              <ErrorIcon />
            </span>
            {error}
          </Form.Control.Feedback>
        </Form.Check>
      </Form.Group>
    );
  }
);

FormCheckbox.displayName = 'FormCheckbox';

FormCheckbox.propTypes = {
  error: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.element]).isRequired,
  required: PropTypes.bool,
  labelClassName: PropTypes.string,
  inputClassName: PropTypes.string,
};

FormCheckbox.defaultProps = {
  error: '',
  required: false,
  labelClassName: '',
  inputClassName: '',
};

export default FormCheckbox;
