import React, { useEffect } from 'react';
import { Form } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';

import FormPasswordInput from 'components/FormPasswordInput';
import Button from 'components/Button';
import { validatePassword, validatePasswordMatch } from 'utils/validator';
import { useAccountSettingsContext } from 'layouts/AccountSettingsLayout/contexts/AccountSettingsContext';

import './styles.scss';
import { toastStyle } from 'utils/helper';

const ChangePassword = () => {
  const { changePasswordMutation } = useAccountSettingsContext();
  const {
    register: changePasswordRegister,
    handleSubmit: changePasswordSubmit,
    formState,
    reset: changePasswordReset,
    getValues: changePasswordGetValues,
    trigger: changePasswordTrigger,
    watch,
  } = useForm({
    mode: 'onChange',
    defaultValues: {
      old_password: '',
      confirm_new_password: '',
      new_password: '',
    },
  });
  const { errors: changePasswordErrors, isValid } = formState;
  const newPass = watch('new_password');

  useEffect(() => {
    if (changePasswordGetValues('confirm_new_password')) {
      changePasswordTrigger('confirm_new_password');
    }
  }, [newPass, changePasswordGetValues, changePasswordTrigger]);

  const handleChangePasswordSubmit = () =>
    changePasswordSubmit(async data => {
      try {
        await changePasswordMutation.mutateAsync({
          ...data,
        });
        changePasswordReset();
        toast.success('Password has been updated successfully.', toastStyle);
      } catch (error) {
        const message =
          error?.response?.data?.message?.non_field_errors?.[0] ||
          error?.response?.data?.message?.old_password?.[0] ||
          error?.response?.data?.message?.new_password?.[0] ||
          error?.response?.data?.error;

        toast.error(
          message ||
            'An error occurred while updating the password. Please try again later',
          toastStyle
        );
      }
    });

  return (
    <div className="change-password content-section">
      <h2 className="change-password__header content-header">
        Change password
      </h2>
      <Form
        className="change-password__form"
        onSubmit={handleChangePasswordSubmit()}
      >
        <FormPasswordInput
          label="Current Password"
          placeholder="Please enter password"
          error={changePasswordErrors?.old_password?.message}
          inputClassName="change-password__form__input"
          {...changePasswordRegister('old_password', {
            required: 'Current password is required',
          })}
        />
        <FormPasswordInput
          label="New Password"
          placeholder="Please enter new password"
          error={changePasswordErrors?.new_password?.message}
          inputClassName="change-password__form__input"
          {...changePasswordRegister('new_password', {
            required: 'New password is required',
            validate: value => {
              return validatePassword(value);
            },
          })}
        />
        <FormPasswordInput
          label="Confirm New Password"
          placeholder="Please re-enter new password"
          error={changePasswordErrors?.confirm_new_password?.message}
          inputClassName="change-password__form__input"
          {...changePasswordRegister('confirm_new_password', {
            required: 'Confirm new password is required',
            validate: value => {
              return validatePasswordMatch(
                changePasswordGetValues('new_password'),
                value
              );
            },
          })}
        />
        <div className="change-password__form__action">
          <Button
            label="Cancel"
            type="reset"
            className="change-password__form__action__cancel"
            variant="red-outlined"
            onClick={changePasswordReset}
          />
          <Button
            label="Update"
            className="change-password-form__verify-button"
            type="submit"
            disabled={!isValid}
          />
        </div>
      </Form>
    </div>
  );
};

export default ChangePassword;
