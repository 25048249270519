/* eslint-disable react/prop-types */
import { useHomeScreenContext } from 'layouts/HomeScreenLayout/contexts/HomeScreenContext';
import React, { useState, useEffect, useRef } from 'react';
import { Table } from 'react-bootstrap';
import PropTypes from 'prop-types';

const MAX_TABLE_HEIGHT_IN_PIXELS = 1040; // magic number before table pushes content to next page

const PreviewPrintLayout = ({
  tableData,
  printLayoutHeader,
  printLayoutFooter,
  productionDetails,
  tableHeader,
  tableClassName,
  showTitle,
  classNamePrefix = '',
  tableProps,
}) => {
  const { selectedBudget, getBudgetType } = useHomeScreenContext();
  const [tableState, setTableState] = useState({
    currentRowIndex: 0,
    currentTableIndex: 0,
    tables: [],
  });
  const { currentRowIndex, currentTableIndex, tables } = tableState;
  const tableRefs = useRef([]);
  const className = (classNamePrefix ? classNamePrefix : '') + 'print-layout';

  const setRef = tableIndex => ref => {
    tableRefs.current[tableIndex] = ref;
  };

  useEffect(() => {
    if (tableData?.length && currentRowIndex === 0) {
      setTableState({
        currentRowIndex: 1,
        currentTableIndex: 0,
        tables: [[tableData?.[currentRowIndex]]],
      });
    }
  }, [tableData, currentRowIndex]);

  useEffect(() => {
    if (currentRowIndex >= 1 && currentRowIndex < tableData?.length) {
      const currentTableHeight =
        tableRefs.current[currentTableIndex]?.clientHeight;

      if (currentTableHeight > MAX_TABLE_HEIGHT_IN_PIXELS) {
        // pop the last element in the table
        const newTableIndex = currentTableIndex + 1;
        // push row into new table
        const tableInfo = [...tables];
        const lastRow = tableInfo[currentTableIndex].pop();
        setTableState({
          currentRowIndex: currentRowIndex,
          currentTableIndex: newTableIndex,
          tables: [...tableInfo, [lastRow]],
        });
      } else {
        if (tables[currentTableIndex]) {
          const tableInfo = [...tables];
          // insert as normal
          tableInfo[currentTableIndex].push(tableData[currentRowIndex]);
          setTableState({
            currentTableIndex: currentTableIndex,
            currentRowIndex: currentRowIndex + 1,
            tables: [...tableInfo],
          });
        }
      }
    }
  }, [tableData, currentRowIndex, currentTableIndex, tables]);

  const styles =
    currentRowIndex >= tableData?.length
      ? { minHeight: MAX_TABLE_HEIGHT_IN_PIXELS + 'px' }
      : {};

  return tables?.map((table, t) => {
    const tableFooter = [];
    return (
      <>
        <div
          key={`print-page-${t}`}
          className={className}
          ref={setRef(t)}
          style={styles}
        >
          {printLayoutHeader}
          {t === 0 && (
            <>
              {showTitle && (
                <div className={`${className}__title`}>
                  {selectedBudget?.name} {getBudgetType()}
                </div>
              )}
              {productionDetails}
            </>
          )}
          <div className={`${className}__details`}>
            <Table className={tableClassName} {...tableProps}>
              {tableHeader}
              <tbody>
                {table?.map((row, index) => {
                  if (row.type === 'swimlaneFooter') {
                    tableFooter.push(row);
                    return null;
                  }

                  return row.renderMethod(row.rowData, `${t}-${index}`);
                })}
              </tbody>
              {tableFooter?.length > 0 && (
                <tfoot>
                  {tableFooter?.map(row => row.renderMethod(row.rowData))}
                </tfoot>
              )}
            </Table>
          </div>
          {printLayoutFooter}
        </div>
        {t + 1 < tables.length && <div className="page-break" />}
      </>
    );
  });
};

export default PreviewPrintLayout;
