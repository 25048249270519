import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Form } from 'react-bootstrap';
import { toast } from 'react-toastify';

import Modal from 'components/Modal';
import Button from 'components/Button';
import FormInput from 'components/FormInput';
import FormSelect from 'components/FormSelect';
import { useHomeScreenContext } from 'layouts/HomeScreenLayout/contexts/HomeScreenContext';

import './styles.scss';
import { prepareDropDownOptions } from 'utils/common';
import PropTypes from 'prop-types';
import { toastStyle } from 'utils/helper';

const EditBudgetInsideFolder = props => {
  const {
    createBudgetMutation,
    refetchBudgets,
    budgetTypes,
    showCreateBudget,
    hideCreateBudgetModal,
    getBudgetType,
    selectTab,
    clearBudgetRename,
    refetchBudgetId,
    refetchTabs,
  } = useHomeScreenContext();
  const [budgetTypeOptions, setBudgetTypeOptions] = useState(
    prepareDropDownOptions(
      [{ name: 'Blank Budget (No Template)', id: '' }, ...budgetTypes],
      'name',
      'id'
    )
  );
  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    reset,
    watch,
  } = useForm({
    defaultValues: {
      name: props?.budgetName || '',
      template_type: props?.BudgetTypeData?.toString() || '',
      // template_type: '1' || '',
      folder: props?.parentid,
    },
  });

  useEffect(() => {
    setBudgetTypeOptions(
      prepareDropDownOptions(
        [{ name: 'Blank Budget (No Template)', id: '' }, ...budgetTypes],
        'name',
        'id'
      )
    );
  }, [budgetTypes]);

  const handleCreateBudget = () =>
    handleSubmit(async data => {
      try {
        const response = await createBudgetMutation.mutateAsync({
          ...data,
          template_type: data?.template_type?.value,
          id: props?.budgetId,
          folder: props?.parentid,
        });
        const newBudget = response?.data;
        newBudget?.id && selectTab(newBudget.id, newBudget);
        refetchBudgets?.();
        refetchBudgetId?.();
        toast.success(`Budget updated successfully`, toastStyle);
        reset();
        handleCancel();
        props?.refetch();
        refetchTabs();
        // window?.location?.reload();
      } catch (error) {
        console.log(error);
        const message =
          error?.response?.data?.template_type?.[0] ||
          error?.response?.data?.name?.[0] ||
          error?.response?.data?.non_field_errors?.[0];
        toast.error(
          message ||
            `An error occurred while updating budget. Please try again later`,
          toastStyle
        );
      }
    });

  const handleCancel = () => {
    hideCreateBudgetModal();
    clearBudgetRename();
    reset();
    props?.closeFolder();
  };

  return (
    <Modal
      onHide={handleCancel}
      show={props?.showBudgetFolder}
      size="sm"
      className="create-budget"
      bodyClass="create-budget__body"
      centered={true}
    >
      <h1 className="create-budget__body__header">
        Edit Budget{' '}
        {watch('name') && (
          <span className="budget-name">&ldquo;{watch('name')}&rdquo;</span>
        )}
      </h1>
      <Form
        className="create-budget__body__form"
        onSubmit={handleCreateBudget()}
      >
        <FormInput
          label="Budget Title"
          type="text"
          error={errors?.name?.message}
          placeholder="Please enter a budget title"
          {...register('name', { required: 'Budget title is required' })}
        />
        <FormSelect
          label="Budget Template"
          placeholder="Please select a Template"
          options={budgetTypeOptions}
          error={errors?.template_type?.message}
          name="template_type"
          rules={{
            required: false,
          }}
          control={control}
        />
        <div className="create-budget__body__action">
          <Button
            label="Cancel"
            type="reset"
            className="create-budget__action__cancel"
            variant="red-outlined"
            onClick={handleCancel}
          />
          <Button
            label="Confirm"
            className="create-budget__action__confirm"
            type="submit"
          />
        </div>
      </Form>
    </Modal>
  );
};

EditBudgetInsideFolder.propTypes = {
  parentid: PropTypes.string, // Assuming parentid is a string, adjust the type accordingly
  budgetId: PropTypes.string,
  showBudgetFolder: PropTypes.bool,
  budgetName: PropTypes.string,
  BudgetTypeData: PropTypes.string,
  closeFolder: PropTypes.func,
  refetch: PropTypes.func.isRequired, // Adjust the type based on the actual type of refetch
};

EditBudgetInsideFolder.defaultProps = {
  budgetId: '',
  parentid: '', // Default value for parentid (adjust based on actual default)
  showBudgetFolder: false, // Default value for showAddFolder (adjust based on actual default)
  closeFolder: undefined,
  budgetName: '',
  BudgetTypeData: '',
};

export default EditBudgetInsideFolder;
