import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Form } from 'react-bootstrap';
import { toast } from 'react-toastify';
import PropTypes from 'prop-types';
import Modal from 'components/Modal';
import Button from 'components/Button';
import FormInput from 'components/FormInput';
import FormSelect from 'components/FormSelect';
import { useHomeScreenContext } from 'layouts/HomeScreenLayout/contexts/HomeScreenContext';
import { prepareDropDownOptions } from 'utils/common';
import { toastStyle } from 'utils/helper';

const EditFolder = props => {
  const {
    renameFolderMutation,
    refetchBudgets,
    hideCreateFolderModal,
    renameBudget,
    selectTab,
    clearBudgetRename,
    refetchBudgetId,
  } = useHomeScreenContext();

  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    reset,
    watch,
  } = useForm({
    defaultValues: {
      name: props?.folderName || '',
    },
  });

  const handleCreateBudget = () =>
    handleSubmit(async data => {
      try {
        const response = await renameFolderMutation.mutateAsync({
          ...data,
          id: props?.folderId,
          // parent_folder: props?.parentid,
        });
        const newBudget = response?.data;
        newBudget?.id && selectTab(newBudget?.id, newBudget);
        refetchBudgets?.();
        refetchBudgetId?.();
        toast.success(`Folder updated successfully`, toastStyle);
        reset();
        window.location.reload();
        props?.refetch();
        handleCancel();
      } catch (error) {
        console.log(error);
        const message =
          error?.response?.data?.template_type?.[0] ||
          error?.response?.data?.name?.[0] ||
          error?.response?.data?.non_field_errors?.[0];
        toast.error(
          message ||
            `An error occurred while updating folder. Please try again later`,
          toastStyle
        );
      }
    });

  const handleCancel = () => {
    hideCreateFolderModal();
    clearBudgetRename();
    reset();
    props?.closeFolder();
  };

  return (
    <Modal
      onHide={handleCancel}
      show={props?.showEditFolder}
      size="sm"
      className="create-budget"
      bodyClass="create-budget__body"
      centered={true}
    >
      <h1 className="create-budget__body__header">
        Rename Folder{' '}
        {watch('name') && (
          <span className="budget-name">&ldquo;{watch('name')}&rdquo;</span>
        )}
      </h1>
      <Form
        className="create-budget__body__form"
        onSubmit={handleCreateBudget()}
      >
        <FormInput
          label="Folder Name"
          type="text"
          error={errors?.name?.message}
          placeholder="Please enter a folder name"
          {...register('name', { required: 'Folder name is required' })}
        />
        <div className="create-budget__body__action">
          <Button
            label="Cancel"
            type="reset"
            className="create-budget__action__cancel"
            variant="red-outlined"
            onClick={handleCancel}
          />
          <Button
            label="Confirm"
            className="create-budget__action__confirm"
            type="submit"
          />
        </div>
      </Form>
    </Modal>
  );
};

EditFolder.propTypes = {
  folderId: PropTypes.string, // Assuming parentid is a string, adjust the type accordingly
  showEditFolder: PropTypes.bool,
  closeFolder: PropTypes.func,
  folderName: PropTypes.string,
  refetch: PropTypes.func.isRequired,
};

EditFolder.defaultProps = {
  folderId: '', // Default value for parentid (adjust based on actual default)
  showEditFolder: false, // Default value for showAddFolder (adjust based on actual default)
  closeFolder: undefined,
  folderName: '',
};

export default EditFolder;
