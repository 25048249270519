import React from 'react';
import PropTypes from 'prop-types';

import './styles.scss';

const Avatar = ({
  name,
  image,
  onClick,
  className,
  wrapperStyle,
  imageStyle,
}) => {
  const initials = name
    ?.split(' ')
    ?.map(part => (part[0] == '(' ? '' : part[0]))
    ?.join('')
    ?.toUpperCase();

  return (
    <div
      className={`avatar-wrapper ${className}`}
      onClick={onClick}
      style={{ ...wrapperStyle }}
    >
      <div className="avatar-icon" style={{ ...imageStyle }} title={name}>
        {image || initials}
      </div>
    </div>
  );
};

Avatar.propTypes = {
  name: PropTypes.string,
  image: PropTypes.element,
  onClick: PropTypes.func,
  className: PropTypes.string,
  wrapperStyle: PropTypes.object,
  imageStyle: PropTypes.object,
};

Avatar.defaultProps = {
  name: 'Not Available',
  image: null,
  onClick: () => null,
  className: '',
  wrapperStyle: {},
  imageStyle: {},
};

export default Avatar;
