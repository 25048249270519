import React, { createContext, useContext } from 'react';
import PropTypes from 'prop-types';

import { useAccountSettingMutation } from 'utils/api/mutations';

const accountSettingsContext = createContext({});

export const AccountSettingsContextProvider = ({ children }) => {
  const changePasswordMutation =
    useAccountSettingMutation.useChangePasswordMutation();
  const userProfileMutation =
    useAccountSettingMutation.useUserProfileMutation();

  return (
    <accountSettingsContext.Provider
      value={{
        changePasswordMutation,
        userProfileMutation,
      }}
    >
      {children}
    </accountSettingsContext.Provider>
  );
};

AccountSettingsContextProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export const useAccountSettingsContext = () => {
  const { changePasswordMutation, userProfileMutation } = useContext(
    accountSettingsContext
  );

  return {
    changePasswordMutation,
    userProfileMutation,
  };
};
