import axios from 'axios';
import { toast } from 'react-toastify';

import APP_CONFIG from 'config/app-config';
import ALL_ROUTES from 'config/routes';
import { getAuthToken } from 'utils/common';
import { toastStyle } from 'utils/helper';

axios.defaults.baseURL = APP_CONFIG.baseWebApiUrl;

export const Request = axios.create();

let navigate;

export const injectNavigator = navigator => {
  navigate = navigator;
};

const notLoginCall = config => {
  const endPoints = [
    '/api/v1/login/',
    '/api/v1/send-email-otp/',
    '/api/v1/signup/',
    '/api/v1/verify-email-otp/',
    '/api/v1/resend-email-otp/',
    '/api/v1/forgot-password/',
    '/api/v1/forgot-password-verify-otp/',
    '/api/v1/resend-forgot-password/',
    '/modules/social-auth/google/login/',
    '/modules/social-auth/facebook/login/',
    '/modules/social-auth/apple/login/',
  ];
  return !endPoints.includes(config?.url);
};

const tokenInterceptor = Request.interceptors.request.use(
  req => {
    if (notLoginCall(req)) {
      const authToken = getAuthToken();
      if (authToken) {
        req.headers['Authorization'] = `Token ${authToken}`;
      }
    }
    return req;
  },
  null,
  { synchronous: true }
);

const catchErrors = err => {
  if (401 === err?.response?.status) {
    if (navigate) {
      navigate(ALL_ROUTES.AUTH_CHILDREN.LOGIN, { replace: true });
    } else {
      window.location = ALL_ROUTES.AUTH_CHILDREN.LOGIN;
    }
  } else if (403 === err?.response?.status) {
    toast.dismiss();
    if (/user-logout\/$/.test(err.request.responseURL)) {
      if (navigate) {
        navigate(ALL_ROUTES.AUTH_CHILDREN.LOGIN, { replace: true });
      } else {
        window.location = ALL_ROUTES.AUTH_CHILDREN.LOGIN;
      }
    } else {
      toast.error('The action you have performed is unauthorized.', toastStyle);
    }
  }

  throw err;
};

export const post = (url, data, config) => {
  return Request.post(url, data, config).catch(catchErrors);
};

export const patch = (url, data, config) => {
  return Request.patch(url, data, config).catch(catchErrors);
};

export const put = (url, data, config) => {
  return Request.put(url, data, config).catch(catchErrors);
};

export const del = (url, data, config = {}) => {
  return Request.delete(url, { ...config, data }).catch(catchErrors);
};

export const get = (url, config) => {
  return Request.get(url, config).catch(catchErrors);
};
