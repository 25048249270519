import React, { createContext, useContext } from 'react';
import PropTypes from 'prop-types';

import { useOnboardingMutation } from 'utils/api/mutations';

const authContext = createContext({});

export const AuthContextProvider = ({ children }) => {
  const loginMutation = useOnboardingMutation.useLoginMutation();
  const verifyEmailMutation = useOnboardingMutation.useVerifyEmailMutation();
  const signupMutation = useOnboardingMutation.useSignupMutation();
  const resendOtpMutation = useOnboardingMutation.useResendOTPMutation();
  const verifyOtpMutation = useOnboardingMutation.useVerifyOTPMutation();
  const forgotPasswordMutation =
    useOnboardingMutation.useForgotPasswordMutation();
  const resendForgotPasswordMutation =
    useOnboardingMutation.useResendForgotPasswordMutation();
  const verifyForgotPasswordMutation =
    useOnboardingMutation.useVerifyForgotPasswordMutation();
  const socialLoginMutation = useOnboardingMutation.useSocialLoginMutation();

  const handleSocialLogin = async (provider, payload, onSuccess, onError) => {
    try {
      const response = await socialLoginMutation.mutateAsync({
        provider,
        payload,
      });
      onSuccess(response);
    } catch (error) {
      onError(error);
    }
  };

  return (
    <authContext.Provider
      value={{
        loginMutation,
        signupMutation,
        resendOtpMutation,
        verifyOtpMutation,
        forgotPasswordMutation,
        resendForgotPasswordMutation,
        verifyForgotPasswordMutation,
        verifyEmailMutation,
        handleSocialLogin,
      }}
    >
      {children}
    </authContext.Provider>
  );
};

AuthContextProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export const useAuthContext = () => {
  const {
    loginMutation,
    signupMutation,
    resendOtpMutation,
    verifyOtpMutation,
    forgotPasswordMutation,
    resendForgotPasswordMutation,
    verifyForgotPasswordMutation,
    verifyEmailMutation,
    handleSocialLogin,
  } = useContext(authContext);

  return {
    loginMutation,
    signupMutation,
    resendOtpMutation,
    verifyOtpMutation,
    forgotPasswordMutation,
    resendForgotPasswordMutation,
    verifyForgotPasswordMutation,
    verifyEmailMutation,
    handleSocialLogin,
  };
};
