import React, {
  useState,
  useEffect,
  useCallback,
  useMemo,
  useRef,
} from 'react';
import { useForm } from 'react-hook-form';
import { Country, State } from 'country-state-city';
import { toast } from 'react-toastify';

import Card from 'components/Card';
import FormInput from 'components/FormInput';
import FormSelect from 'components/FormSelect';
import FormPhoneInput from 'components/FormPhoneInput';
import { profileOptions } from 'utils/constants';
import { ReactComponent as ProfileImageIcon } from 'assets/images/profile-image-icon.svg';
import { ReactComponent as UploadIcon } from 'assets/images/upload-icon.svg';
import { ReactComponent as LockIcon } from 'assets/images/lock.svg';

import './styles.scss';
import Button from 'components/Button';
import { useAppContext } from 'contexts/AppContext';
import { useAccountSettingsContext } from 'layouts/AccountSettingsLayout/contexts/AccountSettingsContext';
import {
  compareFormValues,
  prepareAddressInfo,
  prepareContactInfo,
  prepareProfileInfo,
} from 'utils/common';
import UploadFile from 'components/UploadFile';
import { toastStyle } from 'utils/helper';
//import { toastStyle } from 'utils/helper';

const Profile = () => {
  const phoneInputKey = useRef(new Date().valueOf());
  const [countryOptions, setCountryOptions] = useState([]);
  const [stateOptions, setStateOptions] = useState([]);
  const [uploadStatus, setUploadStatus] = useState({
    uploading: false,
    percentCompleted: 0,
  });
  const [editMode, setEditMode] = useState(false);
  const { userData, userProfile, isFetchingProfile, refetchProfile } =
    useAppContext();
  const { userProfileMutation } = useAccountSettingsContext();

  const profileInfo = useMemo(
    () => prepareProfileInfo(userProfile, userData),
    [userProfile, userData]
  );
  const contactInfo = useMemo(() => {
    const contactData = prepareContactInfo(userProfile, userData);
    if (contactData?.phone_number) {
      phoneInputKey.current = new Date().valueOf();
    }
    return contactData;
  }, [userProfile, userData]);

  const addressInfo = useMemo(
    () => prepareAddressInfo(userProfile),
    [userProfile]
  );

  const enableEditMode = () => setEditMode(true);

  const {
    register: profileFormRegister,
    handleSubmit: profileFormSubmit,
    formState: { errors: profileFormErrors },
    watch: profileFormWatch,
    reset: resetProfileForm,
    control: profileFormControl,
  } = useForm({ mode: 'onChange' });
  const profileValues = profileFormWatch();

  const {
    handleSubmit: contactFormSubmit,
    formState: { errors: contactFormErrors },
    reset: resetContactForm,
    watch: contactFormWatch,
    control: contactFormControl,
  } = useForm({ mode: 'onChange' });
  const contactValues = contactFormWatch();

  const {
    register: addressFormRegister,
    handleSubmit: addressFormSubmit,
    formState: { errors: addressFormErrors },
    watch: addressFormWatch,
    reset: resetAddressForm,
    setValue: addressFormSetValue,
    control: addressFormControl,
  } = useForm({ mode: 'onChange' });
  const addressValues = addressFormWatch();

  const resetForms = useCallback(() => {
    resetAddressForm(addressInfo);
    resetContactForm(contactInfo);
    resetProfileForm(profileInfo);
    setEditMode(false);
    phoneInputKey.current = new Date().valueOf();
  }, [
    addressInfo,
    contactInfo,
    profileInfo,
    resetAddressForm,
    resetContactForm,
    resetProfileForm,
  ]);

  useEffect(() => {
    !isFetchingProfile && resetForms();
  }, [isFetchingProfile, resetForms]);

  const selectedCountry = addressFormWatch('country');
  useEffect(() => {
    const countries = Country.getAllCountries();
    const updatedCountries = countries.map(country => ({
      label: country.name,
      value: country.isoCode,
    }));
    setCountryOptions(updatedCountries);
  }, []);

  useEffect(() => {
    const updatedStates = State.getStatesOfCountry(
      typeof selectedCountry === 'object'
        ? selectedCountry?.value
        : selectedCountry
    ).map(state => ({ label: state.name, value: state.isoCode }));

    setStateOptions(updatedStates);
    addressFormSetValue('state', null);
    addressFormSetValue('city', '');
    addressFormSetValue('zip_code', '');
  }, [selectedCountry, addressFormSetValue]);

  const profileModified = useMemo(
    () =>
      profileValues ? compareFormValues(profileValues, profileInfo) : false,
    [profileValues, profileInfo]
  );

  const contactModified = useMemo(
    () =>
      contactValues ? compareFormValues(contactValues, contactInfo) : false,
    [contactValues, contactInfo]
  );

  const addressModified = useMemo(
    () =>
      addressValues ? compareFormValues(addressValues, addressInfo) : false,
    [addressValues, addressInfo]
  );

  const hasChanges = useMemo(() => {
    return profileModified || contactModified || addressModified;
  }, [profileModified, contactModified, addressModified]);

  const handleOnSave = async () => {
    let profileChanges = null;

    profileModified &&
      (await profileFormSubmit(() => {
        profileChanges = {
          name: profileValues?.name,
          user_type: profileValues?.user_type?.value,
        };
      })());

    let contactChanges = null;
    contactModified &&
      (await contactFormSubmit(() => {
        contactChanges = {
          userprofile: {
            phone_number: contactValues?.phone_number,
          },
        };
      })());

    let addressChanges = null;
    addressModified &&
      (await addressFormSubmit(() => {
        addressChanges = {
          useraddress: {
            address_line_1: addressValues?.address_line_1,
            address_line_2: addressValues?.address_line_2,
            country: addressValues?.country?.value,
            state: addressValues?.state?.value || '',
            city: addressValues?.city,
            zip_code: addressValues?.zip_code,
          },
        };
      })());

    if (
      !(
        (!profileModified || profileChanges) &&
        (!contactModified || contactChanges) &&
        (!addressModified || addressChanges)
      )
    )
      return false;

    const userInfo = {
      id: userProfile?.id,
      ...profileChanges,
      ...contactChanges,
      ...addressChanges,
    };

    userProfileMutation.mutate(userInfo, {
      onSuccess: () => {
        refetchProfile?.();
        setEditMode(false);
        toast.success('Profile updated successfully', toastStyle);
      },
      onError: error => {
        const message =
          error?.response?.data?.error ||
          error?.response?.data?.message?.non_field_errors?.[0] ||
          error?.response?.data?.non_field_errors?.[0];
        toast.error(
          message ||
            'An error occurred while updating the profile. Please try again later.',
          toastStyle
        );
      },
    });
  };

  const handleUploadFile = file => {
    userProfileMutation.mutate(
      {
        profile_image: file,
        id: userProfile?.id,
        setUploadStatus,
        headers: {
          headers: {
            Accept: 'application/json',
            'Content-Type': 'multipart/form-data',
          },
        },
      },
      {
        onSuccess: () => {
          refetchProfile?.();
          toast.success('Profile image uploaded successfully', toastStyle);
          setUploadStatus({
            uploading: false,
            percentCompleted: 0,
          });
        },
        onError: () => {
          toast.error(
            'An error occurred while uploading the profile image. Please try again later.',
            toastStyle
          );
          setUploadStatus({
            uploading: false,
            percentCompleted: 0,
          });
        },
      }
    );
  };

  return (
    <div className="user-profile content-section">
      <h2 className="user-profile__header content-header">
        Profile
        <div className="user-profile__header__actions">
          {editMode ? (
            <>
              <Button
                label="Save"
                type="button"
                onClick={handleOnSave}
                className="user-profile-save-btn"
                variant="primary"
                disabled={!hasChanges}
              />
              <Button
                label="Cancel"
                type="reset"
                onClick={resetForms}
                className="user-profile-cancel-btn"
                variant="red-outlined"
              />
            </>
          ) : (
            <Button
              label="Edit"
              type="button"
              onClick={enableEditMode}
              className="user-profile-edit-btn"
              variant="primary"
            />
          )}
        </div>
      </h2>
      <div className="user-profile__content content-body row">
        <div className="user-profile__content__col col-md-4">
          <Card className="personal-info" title="Personal Details">
            <FormInput
              label="Full Name"
              type="text"
              placeholder="Please enter your full name"
              error={profileFormErrors?.name?.message}
              {...profileFormRegister('name', {
                required: 'Name is required',
              })}
              readOnly={!editMode}
            />
            {/* {editMode ? (
              <FormSelect
                label="Account Type"
                placeholder="Please select an account type"
                options={profileOptions}
                error={profileFormErrors?.user_type?.message}
                name="user_type"
                rules={{
                  required: 'Account type is required',
                }}
                control={profileFormControl}
              />
            ) : (
              <FormInput
                label="Account Type"
                type="text"
                placeholder="Please select an account type"
                value={
                  profileOptions?.find(
                    option => profileInfo?.user_type === option?.value
                  )?.label || ''
                }
                readOnly={!editMode}
              />
            )} */}
          </Card>
          <Card className="profile-image" title="Profile Image">
            <UploadFile
              handleUploadFile={handleUploadFile}
              uploadStatus={uploadStatus}
            >
              <div className="profile-image__content">
                <div className="profile-image__content__thumbnail">
                  {userProfile?.userprofile?.profile_image ? (
                    <img
                      src={userProfile?.userprofile?.profile_image}
                      alt="profile-pic"
                    />
                  ) : (
                    <ProfileImageIcon />
                  )}
                </div>
                <div className="profile-image__content__upload">
                  <Button
                    label={
                      <span className="upload-label">
                        Upload Image
                        <UploadIcon />
                      </span>
                    }
                    type="reset"
                    className="profile-image-upload-button"
                    variant="red-outlined"
                  />
                  {!userProfile?.userprofile?.profile_image && (
                    <div className="no-image-text">
                      No image has been uploaded
                    </div>
                  )}
                </div>
              </div>
            </UploadFile>
          </Card>
        </div>
        <div className="user-profile__content__col col-4">
          <Card className="contact-info" title="Contact Details">
            <FormInput
              label="Email"
              type="email"
              placeholder="Please enter your email"
              readOnly={true}
              disabled={true}
              helpText={
                <div className="help-text">
                  <LockIcon className="help-text__icon" />
                  <span className="help-text__content">
                    Sign Up email will remain fixed
                  </span>
                </div>
              }
              value={contactInfo?.email}
            />
            <FormPhoneInput
              label="Phone"
              type="text"
              placeholder="(555) 123-5555"
              error={contactFormErrors?.phone_number?.message}
              control={contactFormControl}
              name="phone_number"
              disabled={!editMode}
              rules={{
                required: 'Phone is required',
                validate: value => {
                  if (!editMode) {
                    return true;
                  }
                  const numberParts = value?.split(' ') || [];
                  const number = numberParts
                    ?.slice(1)
                    ?.join('')
                    ?.trim()
                    ?.replace(/\D/, '');

                  if (!number?.trim()) {
                    return 'Phone is required';
                  }
                  if (number?.length < 8) {
                    return 'Please enter valid phone number';
                  }
                  return true;
                },
              }}
              defaultValue={contactInfo?.phone_number || ''}
            />
          </Card>
        </div>
        <div className="user-profile__content__col col-4">
          <Card className="address-info" title="Address Details">
            <FormInput
              label="Address Line 1"
              type="text"
              placeholder="891 - St Fran Blvd"
              error={addressFormErrors?.address_line_1?.message}
              {...addressFormRegister('address_line_1', {
                required: 'Address line 1 is required',
              })}
              readOnly={!editMode}
            />
            <FormInput
              label="Address Line 2"
              type="text"
              placeholder="Welignton St., Block - D"
              error={addressFormErrors?.address_line_2?.message}
              {...addressFormRegister('address_line_2', { required: false })}
              readOnly={!editMode}
            />
            {editMode ? (
              <>
                <FormSelect
                  label="Country"
                  placeholder="United States of America"
                  options={countryOptions}
                  error={addressFormErrors?.country?.message}
                  name="country"
                  isSearchable={true}
                  rules={{
                    required: 'Country is required',
                  }}
                  control={addressFormControl}
                />
                <div className={stateOptions?.length > 0 ? '' : 'd-none'}>
                  <FormSelect
                    label="State"
                    placeholder="California (CA)"
                    options={stateOptions}
                    error={addressFormErrors?.state?.message}
                    name="state"
                    isSearchable={true}
                    rules={{
                      required:
                        stateOptions?.length > 0 ? 'state is required' : false,
                    }}
                    control={addressFormControl}
                  />
                </div>
              </>
            ) : (
              <>
                <FormInput
                  label="Country"
                  type="text"
                  placeholder="United States of America"
                  readOnly={!editMode}
                  value={
                    countryOptions?.find(
                      country => country.value === addressInfo?.country
                    )?.label || ''
                  }
                />
                <FormInput
                  label="State"
                  type="text"
                  placeholder="California (CA)"
                  readOnly={!editMode}
                  value={
                    stateOptions?.find(
                      state => state.value === addressInfo?.state
                    )?.label || ''
                  }
                />
              </>
            )}
            <FormInput
              label="City"
              type="text"
              placeholder="Beverly Hills"
              error={addressFormErrors?.city?.message}
              {...addressFormRegister('city', { required: 'City is required' })}
              readOnly={!editMode}
            />
            <FormInput
              label="Zip Code"
              type="text"
              placeholder="90210"
              error={addressFormErrors?.zip_code?.message}
              {...addressFormRegister('zip_code', {
                required: 'Zip code is required',
              })}
              readOnly={!editMode}
            />
          </Card>
        </div>
      </div>
    </div>
  );
};

export default Profile;
