import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { Form } from 'react-bootstrap';
import { ReactComponent as Expand } from 'assets/images/expand-icon.svg';
import { ReactComponent as ErrorIcon } from 'assets/images/error-outline.svg';
import ReactDOMServer from 'react-dom/server';
import CommentPopup from '../CommentPopup';
import './styles.scss';

const FormInput = React.forwardRef(
  (
    {
      error,
      hiddenColumn,
      callHiddenComment,
      editComment,
      type,
      label,
      placeholder,
      onClick,
      onKeyDown,
      inputClassName,
      labelClassName,
      formControlValue,
      itemType,
      rowId,
      autoFocus,
      rowData,
      helpText,
      showAutofocus,
      isComment,
      stopOnChange,
      ...rest
    },
    ref
  ) => {
    const inputRef = useRef(null);
    const [isClicked, setIsClicked] = useState(false);
    const [showCommentPopup, setShowCommentPopup] = useState(false);
    const [showCommentData, setShowCommentData] = useState(null);
    const [isEditing, setIsEditing] = useState(false);

    useEffect(() => {
      if (inputRef && (showAutofocus || isEditing)) {
        inputRef?.current?.focus();
      }
    }, [showAutofocus, inputRef, isEditing]);

    useEffect(() => {
      // Check if the current rowId is the one that was clicked
      if (
        itemType === 'comment' &&
        rowData?.comment !== '' &&
        rowData?.comment !== undefined
      ) {
        if (isEditing === false) {
          setIsClicked(true);
        }
      }
    }, [rowData?.comment]);

    const handleStartEditing = () => {
      if (hiddenColumn?.comment === true) {
        callHiddenComment();
      }
      if (itemType === 'comment' && hiddenColumn?.comment !== true) {
        setIsEditing(true);
        setIsClicked(false);
        if (inputRef && (showAutofocus || isEditing)) {
          inputRef?.current?.focus();
        }
      }
    };

    const handleFinishEditing = () => {
      if (
        itemType === 'comment' &&
        rowData?.comment !== '' &&
        rowData?.comment !== undefined
      ) {
        setIsClicked(true);
        setIsEditing(false);
      }
    };

    const parseComment = comment => {
      const urlRegex = /(https?:\/\/[^\s]+|www\.[^\s]+)/g;
      const parts = comment.split(/\s+/);
      const matches = comment?.match(urlRegex);

      return parts?.map((part, index) => {
        if (part) {
          // const isURL = matches && matches.includes(part);
          const isURL = part && part?.match(urlRegex);
          const url = isURL
            ? part.startsWith('http')
              ? part
              : `https://${part}`
            : null;
          const isURLFlag = isURL ? isURL[0] : isURL;
          const trimmedPart =
            part.length > 48 ? `${part.slice(0, 49)}...` : part;
          if (isURLFlag?.length > 0) {
            // Handle links separately to avoid reiteration
            return (
              <>
                <a
                  key={index}
                  href={url}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {trimmedPart}
                </a>
                &nbsp;
              </>
            );
          } else {
            // Render non-link parts
            return <span key={index}>{trimmedPart}&nbsp;</span>;
          }
        } else {
          return null;
        }
      });
    };

    const handleEditComment = () => {
      setShowCommentData(rowData);
      setShowCommentPopup(true);
    };

    const saveEditComment = val => {
      const obj = {
        target: {
          value: val,
        },
      };
      if (val === '' || val === undefined || val === null) {
        setIsClicked(false);
      }
      editComment(obj);
    };
    const handleClosePopup = () => {
      setShowCommentPopup(false);
    };

    return (
      <>
        <Form.Group className="form-input-group">
          {label && (
            <Form.Label
              className={`d-flex align-item-start w-100 form-input-group__label ${labelClassName}`}
            >
              {label}
            </Form.Label>
          )}
          {!isClicked && (
            <Form.Control
              type={type}
              placeholder={placeholder}
              isInvalid={Boolean(error)}
              ref={refInput => {
                inputRef.current = refInput;
                if (typeof ref === 'function') {
                  ref(refInput);
                }
              }}
              className={`form-input-group__input ${inputClassName}`}
              autoComplete="off"
              as={type === 'textarea' ? 'textarea' : 'input'}
              rows={3}
              {...rest}
              onClick={onClick}
              onKeyDown={onKeyDown}
              onBlur={e => {
                if (itemType === 'comment') {
                  handleFinishEditing?.();
                } else {
                  rest.onBlur(e);
                }
              }}
              onChange={e => {
                if (stopOnChange) {
                  return;
                }
                if (itemType === 'comment') {
                  handleStartEditing?.();
                  rest.onChange(e);
                } else {
                  rest.onChange(e);
                }
              }}
              autoFocus={autoFocus}
              onFocus={e => {
                if (itemType === 'rate') {
                  rest.onFocus(e);
                }
              }}
            />
          )}
          {isClicked && (
            <div
              onClick={() => handleStartEditing()}
              className={` ${inputClassName}label-cls`}
            >
              <label className={`${inputClassName}-comment-label`}>
                {parseComment(rowData.comment)}
              </label>
            </div>
          )}
          {helpText && (
            <Form.Text className="comment_txt">{helpText}</Form.Text>
          )}
          {error && (
            <Form.Control.Feedback type="invalid">
              <span className="error-icon">
                <ErrorIcon />
              </span>
              {error}
            </Form.Control.Feedback>
          )}
          &nbsp;
          {itemType === 'comment' &&
            rowData?.comment !== '' &&
            rowData?.comment !== undefined &&
            hiddenColumn?.comment !== true && (
              <span className="close-icon">
                <Expand onClick={() => handleEditComment()} />
              </span>
            )}
        </Form.Group>
        {showCommentPopup === true && (
          <CommentPopup
            show={showCommentPopup}
            commentdata={showCommentData}
            handleClosePopup={handleClosePopup}
            onChange={saveEditComment}
          />
        )}
      </>
    );
  }
);

FormInput.displayName = 'FormInput';

FormInput.propTypes = {
  autoFocus: PropTypes.bool,
  type: PropTypes.string,
  error: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  helpText: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  labelClassName: PropTypes.string,
  inputClassName: PropTypes.string,
  placeholder: PropTypes.string,
  onClick: PropTypes.func,
  onKeyDown: PropTypes.func,
  formControlValue: PropTypes.string,
  itemType: PropTypes.string,
  rowId: PropTypes.number,
  rowData: PropTypes.object,
  showAutofocus: PropTypes.Boolean,
  isComment: PropTypes.string,
  editComment: PropTypes.func,
  hiddenColumn: PropTypes.Boolean,
  callHiddenComment: PropTypes.func,
  stopOnChange: PropTypes.bool,
};

FormInput.defaultProps = {
  hiddenColumn: false,
  callHiddenComment: () => null,
  autoFocus: false,
  editComment: () => null,
  error: '',
  label: '',
  type: 'text',
  labelClassName: '',
  inputClassName: '',
  placeholder: '',
  helpText: '',
  formControlValue: '',
  itemType: '',
  onClick: () => null,
  onKeyDown: () => null,
  rowId: 0,
  showAutofocus: false,
  rowData: {},
  isComment: '',
  stopOnChange: false,
};

export default FormInput;
