import React, { useEffect, useState } from 'react';
import validator from 'validator';
import { useForm } from 'react-hook-form';
import { Form } from 'react-bootstrap';
import { toast } from 'react-toastify';

import { Modal } from 'react-bootstrap';
import Button from 'components/Button';
import FormInput from 'components/FormInput';
import FormSelect from 'components/FormSelect';
import { useHomeScreenContext } from 'layouts/HomeScreenLayout/contexts/HomeScreenContext';
import { ReactComponent as Edit } from 'assets/images/edit-icon.svg';

import './styles.scss';
import { prepareDropDownOptions } from 'utils/common';
import PropTypes from 'prop-types';

const CommentPopup = props => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    reset,
    watch,
  } = useForm({
    defaultValues: {
      //   name: props?.commentdata?.comment || '',
    },
  });
  const [showTextBox, setShowTextBox] = useState(false);
  const [getText, setText] = useState(props?.commentdata?.comment);
  const [textareaHeight, setTextareaHeight] = useState('auto');

  const handleCancel = () => {
    props?.handleClosePopup();
  };
  useEffect(() => {
    if (showTextBox) {
      // Update textarea height when the content changes
      setTextareaHeight(
        `${document.getElementById('comment-text-area').scrollHeight}px`
      );
    }
  }, [getText, showTextBox]);

  const parseComment = comment => {
    const urlRegex = /(https?:\/\/[^\s]+|www\.[^\s]+)/g;
    // const parts = comment.split(' ');
    const parts = comment.split(/\s+/);
    const matches = comment.match(urlRegex);

    return parts.map((part, index) => {
      if (part) {
        const cleanedPart = part.startsWith('\n') ? part.substring(1) : part;
        // const isURL = matches && matches.includes(part);
        const isURL = part && part.match(urlRegex);
        const url = isURL
          ? part.startsWith('http')
            ? part
            : `https://${part}`
          : null;

        return isURL ? (
          <>
            <a key={url} href={url} target="_blank" rel="noopener noreferrer">
              {url}
            </a>
            &nbsp;
          </>
        ) : (
          <span key={index}>{part}&nbsp;</span>
        );
      } else {
        return null;
      }
    });
  };

  const handleEditComment = () => {
    setShowTextBox(true);
  };

  const handleSaveComment = () => {
    setShowTextBox(false);
    props?.onChange(getText);
    handleCancel();
  };

  return (
    <Modal
      onHide={handleCancel}
      show={props?.show}
      size="sm"
      className="comment-popup"
      bodyClass="create-budget-body"
      centered={true}
    >
      <Modal.Header closeButton>
        <div className="comm-header-wrraper">
          <h1 className="create-budget-body-header">View Note</h1>
          <Edit onClick={handleEditComment} className="edit-icon" />
        </div>
      </Modal.Header>

      <Modal.Body>
        {showTextBox === false ? (
          <div className="comment-body">
            {parseComment(props?.commentdata?.comment)}
          </div>
        ) : (
          <div className="comment-body">
            <textarea
              id="comment-text-area"
              className="form-control"
              value={getText}
              onChange={e => setText(e.target.value)}
              style={{ height: textareaHeight }}
            />
          </div>
        )}
      </Modal.Body>
      <Modal.Footer>
        <div className="edit-save-btns">
          <Button
            label="Save"
            className="budget-settings__action__cancel"
            onClick={handleSaveComment}
          />
        </div>
      </Modal.Footer>
    </Modal>
  );
};
CommentPopup.propTypes = {
  show: PropTypes.bool,
  commentdata: PropTypes.object,
  handleClosePopup: PropTypes.func,
  onChange: PropTypes.func,
};

CommentPopup.defaultProps = {
  show: false,
  commentdata: {},
  handleClosePopup: () => null,
  onChange: () => null,
};

export default CommentPopup;
