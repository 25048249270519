import React from 'react';
import PropTypes from 'prop-types';

import { useHomeScreenContext } from 'layouts/HomeScreenLayout/contexts/HomeScreenContext';
import { useAppContext } from 'contexts/AppContext';
import CollaboratorListRow from './CollaboratorListRow';

const CollaboratorsList = ({ permissionLabel, handleShareBudget }) => {
  const { collaboratorsList, isBudgetOwner } = useHomeScreenContext();
  const { permissions, userData, userProfile } = useAppContext();

  return (
    <div className="collaborator-list">
      <CollaboratorListRow
        user={{
          name: userData?.name || userProfile?.name,
          email: userData?.email,
          profile_image: userProfile?.userprofile?.profile_image,
        }}
        isOwner={true}
        userRole={
          isBudgetOwner()
            ? 'Owner'
            : permissionLabel(
                collaboratorsList?.registered_collaborators?.find(
                  user => user?.collaborator_user_email === userData?.email
                )?.permissions?.[0]
              ) || ''
        }
      />
      {collaboratorsList?.registered_collaborators
        ?.filter(user => user?.collaborator_user_email !== userData?.email)
        ?.map(user => {
          return (
            <CollaboratorListRow
              key={user?.id}
              user={{
                id: user?.id,
                name: user?.collaborator_name,
                email: user?.collaborator_user_email,
                permissions: user?.permissions,
                profile_image: user?.collaborator_profile_image,
              }}
              permissions={permissions}
              permissionLabel={permissionLabel}
              handleShareBudget={handleShareBudget}
              isRegistered={true}
            />
          );
        })}
      {collaboratorsList?.invited_collaborators?.length > 0 && (
        <div className="invited-title">
          Invited <div className="line" />
        </div>
      )}
      {collaboratorsList?.invited_collaborators?.map(user => {
        return (
          <CollaboratorListRow
            key={user?.id}
            user={{
              id: user?.id,
              email: user?.collaborator_email,
              permissions: user?.permissions,
            }}
            permissions={permissions}
            permissionLabel={permissionLabel}
            handleShareBudget={handleShareBudget}
          />
        );
      })}
    </div>
  );
};

CollaboratorsList.propTypes = {
  permissionLabel: PropTypes.func.isRequired,
  handleShareBudget: PropTypes.func.isRequired,
};
export default CollaboratorsList;
