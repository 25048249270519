import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import { useHomeScreenContext } from 'layouts/HomeScreenLayout/contexts/HomeScreenContext';
import {
  formatCurrency,
  formatDateTime,
  indexToAlpha,
  isArray,
  sortArray,
} from 'utils/helper';
import { getColumnConf } from 'utils/common';
import SwimlanePage from '../SwimlanePage';
import GridLoader from 'components/GridLoader';
import BudgetPDF from '../BudgetPDF';

import './styles.scss';

const AicpBudgetData = React.forwardRef(
  (
    {
      includeComments,
      includeProductionDetails,
      budgetData,
      isLoading,
      pdfGeneratorRef,
      getLabel,
    },
    ref
  ) => {
    const { selectedBudget, getBudgetType } = useHomeScreenContext();
    const columns = getColumnConf(selectedBudget);
    const [budgetTotal, setBudgetTotal] = useState(0);
    const [contingency, setContingency] = useState(0);

    useEffect(() => {
      const totalCost =
        budgetData?.budget_swimlane?.reduce((total, swimlane) => {
          total = total + parseFloat(swimlane?.cost || 0);

          return total;
        }, 0) || 0;

      setBudgetTotal(totalCost);
      calculateContingency(totalCost);
    }, [budgetData]);

    const calculateContingency = total => {
      setContingency(total * 0.1);
    };

    const printLayoutHeader = () => {
      return (
        <div className="aicp-print-layout__header">
          <div className="budget-name">{budgetData?.name}</div>
          <div className="page-counter"></div>
        </div>
      );
    };

    const printLayoutFooter = () => {
      return (
        <div className="aicp-print-layout__footer">
          <div className="left-section">
            {selectedBudget?.company_name} CONFIDENTIAL
          </div>
          <div className="middle-section">Created with CineBudget Pro</div>
          <div className="right-section">
            Prepared by {selectedBudget?.last_edited_by},{' '}
            {formatDateTime(
              selectedBudget?.updated_on || selectedBudget?.created_on,
              'MMMM Do, YYYY'
            )}
          </div>
        </div>
      );
    };

    const renderProductionDetails = () => (
      <div className="aicp-print-layout__settings">
        <div className="job-info">
          <div className="job-info-header">Job Information</div>
          <div className="job-info-content">
            <div className="left-block">
              <div className="sub-block__item">
                Date{' '}
                {formatDateTime(
                  selectedBudget?.updated_on || selectedBudget?.created_on,
                  'MM/DD/YYYY'
                )}
              </div>
              <div className="sub-block__item">Job Name</div>
              <div className="sub-block__item">Advertiser</div>
              <div className="sub-block__item">Advertiser Address</div>
            </div>
            <div className="center-block">
              <div className="sub-block__item">Advertizer Job Id</div>
              <div className="sub-block__item">Agency Job Id</div>
              <div className="sub-block__item">Production Job Ids:</div>
            </div>
            <div className="right-block">
              <div className="sub-block__item">Brand</div>
              <div className="sub-block__item">Product</div>
              <div className="sub-block__item">Traffic Information:</div>
            </div>
          </div>
        </div>
        <div className="production-info">
          <div className="production-info-header">Production Information</div>
          <div className="production-info-content">
            <div className="left-block">
              <div className="sub-block">
                <div className="sub-block__item">Production Company</div>
                <div className="sub-block__item">Editorial Co</div>
                <div className="sub-block__item">VFX Co</div>
                <div className="sub-block__item">Music Co</div>
              </div>
              <div className="sub-block">
                <div className="sub-block__item">Production Contact</div>
                <div className="sub-block__item">Email</div>
                <div className="sub-block__item">Editorial Producer</div>
                <div className="sub-block__item">Email</div>
                <div className="sub-block__item">VFX Producer</div>
                <div className="sub-block__item">Email</div>
                <div className="sub-block__item">Music Producer</div>
                <div className="sub-block__item">Email</div>
                <div className="sub-block__item">
                  Director(s) {selectedBudget?.director}
                </div>
              </div>
              <div className="sub-block">
                <div className="sub-block__item">editor</div>
                <div className="sub-block__item">
                  Add&apos;l Post Creative(s)
                </div>
              </div>
            </div>
            <div className="center-block">
              <div className="sub-block">
                <div className="sub-block__item">Agency</div>
                <div className="sub-block__item">Agency Address</div>
              </div>
              <div className="sub-block">
                <div className="sub-block__item">Agency Producer</div>
                <div className="sub-block__item">Phone</div>
                <div className="sub-block__item">Email</div>
                <div className="sub-block__item">Agency Address</div>
              </div>
              <div className="sub-block">
                <div className="sub-block__item">Agency Creative Team</div>
              </div>
              <div className="sub-block">
                <div className="sub-block__item">Agency Business Mgr</div>
                <div className="sub-block__item">Phone</div>
                <div className="sub-block__item">Email</div>
              </div>
            </div>
            <div className="right-block">
              <div className="sub-block">
                <div className="sub-block__item">Pre-Production Days</div>
                <div className="sub-block__item">Build & Strike Days</div>
                <div className="sub-block__item">Pre Light:</div>
                <div className="sub-block__item">Stage days:</div>
                <div className="sub-block__item">Location Days:</div>
                <div className="sub-block__item">Locations:</div>
                {selectedBudget?.shooting_locations?.map((location, index) => (
                  <div className="sub-block__item" key={`${location}-${index}`}>
                    Shooting Location: {location}
                  </div>
                ))}
              </div>
              <div className="sub-block">
                <div className="sub-block__item">Spot Title & Lengths:</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );

    const productionDetailsConf = () => [
      {
        table: {
          widths: ['100%'],
          body: [
            [
              {
                text: 'Job Information',
                align: 'left',
                fontSize: '9',
                color: 'white',
                fillColor: '#4285F4',
                bold: true,
              },
            ],
            [
              {
                columns: [
                  [
                    {
                      text: `Date ${formatDateTime(
                        selectedBudget?.updated_on ||
                          selectedBudget?.created_on,
                        'MM/DD/YYYY'
                      )}`,
                      fontSize: '9',
                      align: 'left',
                      width: '33%',
                    },
                    {
                      text: 'Job Name',
                      fontSize: '9',
                      align: 'left',
                      width: '33%',
                    },
                    {
                      text: 'Advertiser',
                      fontSize: '9',
                      align: 'left',
                      width: '33%',
                    },
                    {
                      text: 'Advertiser Address',
                      fontSize: '9',
                      align: 'left',
                      width: '33%',
                      margin: [0, 0, 0, 10],
                    },
                  ],
                  [
                    {
                      text: 'Advertizer Job Id',
                      fontSize: '9',
                      align: 'left',
                      width: '33%',
                    },
                    {
                      text: 'Agency Job Id',
                      fontSize: '9',
                      align: 'left',
                      width: '33%',
                    },
                    {
                      text: 'Production Job Ids:',
                      fontSize: '9',
                      align: 'left',
                      width: '33%',
                    },
                  ],
                  [
                    {
                      text: 'Brand',
                      fontSize: '9',
                      align: 'left',
                      width: '33%',
                    },
                    {
                      text: 'Product',
                      fontSize: '9',
                      align: 'left',
                      width: '33%',
                    },
                    {
                      text: 'Traffic Information:',
                      fontSize: '9',
                      align: 'left',
                      width: '33%',
                    },
                  ],
                ],
              },
            ],
            [
              {
                text: 'Production Information',
                align: 'left',
                fontSize: '9',
                color: 'white',
                fillColor: '#4285F4',
                bold: true,
              },
            ],
            [
              {
                columns: [
                  [
                    {
                      text: 'Production Company',
                      fontSize: '9',
                      align: 'left',
                      width: '33%',
                    },
                    {
                      text: 'editorial Co',
                      fontSize: '9',
                      align: 'left',
                      width: '33%',
                    },
                    {
                      text: 'VFX Co',
                      fontSize: '9',
                      align: 'left',
                      width: '33%',
                    },
                    {
                      text: 'Music Co',
                      fontSize: '9',
                      align: 'left',
                      width: '33%',
                    },

                    {
                      text: 'Production Contact',
                      margin: [0, 10, 0, 0],
                      fontSize: '9',
                      align: 'left',
                      width: '33%',
                    },
                    {
                      text: 'Email',
                      fontSize: '9',
                      align: 'left',
                      width: '33%',
                    },
                    {
                      text: 'Editorial Producer',
                      fontSize: '9',
                      align: 'left',
                      width: '33%',
                    },
                    {
                      text: 'Email',
                      fontSize: '9',
                      align: 'left',
                      width: '33%',
                    },
                    {
                      text: 'VFX Producer',
                      fontSize: '9',
                      align: 'left',
                      width: '33%',
                    },
                    {
                      text: 'Email',
                      fontSize: '9',
                      align: 'left',
                      width: '33%',
                    },
                    {
                      text: 'Music Producer',
                      fontSize: '9',
                      align: 'left',
                      width: '33%',
                    },
                    {
                      text: 'Email',
                      fontSize: '9',
                      align: 'left',
                      width: '33%',
                    },
                    {
                      text: `Director(s) ${selectedBudget?.director || ''}`,
                      fontSize: '9',
                      align: 'left',
                      width: '33%',
                    },
                    {
                      text: 'Editor',
                      margin: [0, 10, 0, 0],
                      fontSize: '9',
                      align: 'left',
                      width: '33%',
                    },
                    {
                      text: "Add'l Post Creative(s)",
                      fontSize: '9',
                      align: 'left',
                      width: '33%',
                      margin: [0, 0, 0, 10],
                    },
                  ],
                  [
                    {
                      text: 'Agency',
                      fontSize: '9',
                      align: 'left',
                      width: '33%',
                    },
                    {
                      text: 'Agency Address',
                      fontSize: '9',
                      align: 'left',
                      width: '33%',
                    },
                    {
                      text: 'Agency Producer',
                      margin: [0, 10, 0, 0],
                      fontSize: '9',
                      align: 'left',
                      width: '33%',
                    },
                    {
                      text: 'Phone',
                      fontSize: '9',
                      align: 'left',
                      width: '33%',
                    },
                    {
                      text: 'Email',
                      fontSize: '9',
                      align: 'left',
                      width: '33%',
                    },
                    {
                      text: 'Agency Address',
                      fontSize: '9',
                      align: 'left',
                      width: '33%',
                    },

                    {
                      text: 'Agency Creative Team',
                      margin: [0, 10],
                      fontSize: '9',
                      align: 'left',
                      width: '33%',
                    },
                    {
                      text: 'Agency Business Mgr',
                      fontSize: '9',
                      align: 'left',
                      width: '33%',
                    },
                    {
                      text: 'Phone',
                      fontSize: '9',
                      align: 'left',
                      width: '33%',
                    },
                    {
                      text: 'Email',
                      fontSize: '9',
                      align: 'left',
                      width: '33%',
                    },
                  ],
                  [
                    {
                      text: 'Pre-Production Days',
                      fontSize: '9',
                      align: 'left',
                      width: '33%',
                    },
                    {
                      text: 'Build & Strike Days',
                      fontSize: '9',
                      align: 'left',
                      width: '33%',
                    },
                    {
                      text: 'Pre Light:',
                      fontSize: '9',
                      align: 'left',
                      width: '33%',
                    },
                    {
                      text: 'Stage days:',
                      fontSize: '9',
                      align: 'left',
                      width: '33%',
                    },
                    {
                      text: 'Location Days:',
                      fontSize: '9',
                      align: 'left',
                      width: '33%',
                    },
                    {
                      text: 'Locations:',
                      fontSize: '9',
                      align: 'left',
                      width: '33%',
                    },
                    ...(selectedBudget?.shooting_locations?.map(location => {
                      return {
                        text: location || '',
                        fontSize: '9',
                        align: 'left',
                        width: '33%',
                      };
                    }) || []),
                    {
                      text: 'Spot Title & Lengths:',
                      margin: [0, 10, 0, 0],
                      fontSize: '9',
                      align: 'left',
                      width: '33%',
                    },
                  ],
                ],
              },
            ],
          ],
        },
      },
    ];

    const swimlaneSummaryHeader = () => {
      return (
        <thead>
          <tr>
            <th colSpan={2}>Summary of Estimated Production Cost</th>
            <th />
          </tr>
          <tr>
            <th colSpan={2}>Production</th>
            <th>Estimate</th>
          </tr>
        </thead>
      );
    };

    const renderSwimlanes = (swimlane, tabIndex) => {
      return (
        <tr key={`swimlane-${swimlane?.id}-${tabIndex}`}>
          <td colSpan={2}>{swimlane?.name}</td>
          <td>
            {formatCurrency(
              swimlane?.cost || 0,
              selectedBudget?.primary_currency
            )}
          </td>
        </tr>
      );
    };

    const renderSwimlaneSummaryFooter = footerRow => {
      return (
        <tr>
          <td style={{ border: '0', background: 'none' }}></td>
          <td>{footerRow?.label}</td>
          <td>
            {formatCurrency(footerRow?.value, selectedBudget?.primary_currency)}
          </td>
        </tr>
      );
    };

    const swimlaneConf = swimlaneData => {
      return [
        {
          table: {
            widths: ['50%', '30%', '20%'],
            headerRows: 2,
            body: [
              [
                {
                  text: 'Summary of Estimated Production Cost',
                  colSpan: 3,
                  alignment: 'left',
                  fontSize: '9',
                  color: 'white',
                  bold: true,
                  fillColor: '#4285F4',
                },
                {},
                {},
              ],
              [
                {
                  text: 'Production',
                  alignment: 'left',
                  fontSize: '9',
                  color: 'white',
                  bold: true,
                  fillColor: '#4285F4',
                  colSpan: 2,
                },
                {},
                {
                  text: 'Estimate',
                  alignment: 'right',
                  fontSize: '9',
                  color: 'white',
                  bold: true,
                  fillColor: '#4285F4',
                },
              ],
              ...(swimlaneData?.map(row => {
                if (row.type === 'swimlaneFooter') {
                  return [
                    { border: [false, false, false, false], text: '' },
                    {
                      text: row?.rowData?.label,
                      alignment: 'left',
                      fontSize: '9',
                      color: 'white',
                      bold: true,
                      fillColor: '#4285F4',
                    },
                    {
                      text: formatCurrency(
                        row?.rowData?.value,
                        selectedBudget?.primary_currency
                      ),
                      alignment: 'right',
                      fontSize: '9',
                      color: 'white',
                      bold: true,
                      fillColor: '#4285F4',
                    },
                  ];
                } else {
                  return [
                    {
                      text: `${row?.rowData?.name}`,
                      alignment: 'left',
                      fontSize: '9',
                      colSpan: 2,
                    },
                    {},
                    {
                      text: formatCurrency(
                        row?.rowData?.cost || 0,
                        selectedBudget?.primary_currency
                      ),
                      alignment: 'right',
                    },
                  ];
                }
              }) || []),
            ],
          },
          layout: {
            hLineWidth: function (i, node) {
              return 0.5;
            },
            vLineWidth: function (i, node) {
              return i === 0 || i === node.table.widths.length ? 1 : 0;
            },
            paddingLeft: function (i, node) {
              return 4;
            },
            paddingRight: function (i, node) {
              return 4;
            },
            paddingTop: function (i, node) {
              return 2;
            },
            paddingBottom: function (i, node) {
              return 2;
            },
            fillColor: function (rowIndex, node, columnIndex) {
              return rowIndex % 2 === 0 &&
                rowIndex !== node.table.body.length - 1
                ? '#F2F2F2'
                : null;
            },
          },
        },
        ...(detailedViewLayoutConf(swimlaneData) || []),
      ];
    };

    const detailedViewLayoutConf = swimlaneData => {
      const cols = columns?.filter(
        col => col.key !== 'comment' || includeComments
      );

      const filteredSwimlanes = swimlaneData?.filter(
        swimlane => swimlane.type !== 'swimlaneFooter'
      );

      return filteredSwimlanes?.map(swimlane => {
        const rows = tableRows(swimlane?.rowData);

        return {
          pageBreak: 'before',
          table: {
            widths: cols?.map(col =>
              col?.key === 'comment'
                ? '*'
                : col?.key === 'item'
                ? includeComments
                  ? '20%'
                  : '*'
                : 'auto'
            ),
            headerRows: 2,
            body: [
              [
                {
                  colSpan: cols.length,
                  alignment: 'left',
                  color: 'white',
                  fillColor: '#4285F4',
                  fontSize: '9',
                  text: `${swimlane?.rowData?.name}`,
                },
                ...Array.from({ length: cols.length - 1 }, () => ({})),
              ],
              [
                ...(cols?.map(col => ({
                  text: getLabel(col.label),
                  alignment: col?.key === 'item_total' ? 'right' : 'left',
                  fontSize: '9',
                  bold: true,
                })) || []),
              ],
              ...(rows?.map(({ rowData, type }) => {
                if (type === 'swimlaneFooter') {
                  return [
                    ...Array.from({ length: rowData?.colSpan }, () => ({
                      text: '',
                      border: [false, false, false, false],
                    })),
                    {
                      fillColor: '#4285F4',
                      color: 'white',
                      border: [true, true, false, true],
                      text: rowData?.label,
                    },
                    {
                      fillColor: '#4285F4',
                      color: 'white',
                      border: [true, true, !includeComments, true],
                      borderLeft: '0',
                      alignment: 'right',
                      text: formatCurrency(
                        rowData?.value,
                        selectedBudget?.primary_currency
                      ),
                    },
                    ...(includeComments
                      ? [
                          {
                            text: '',
                            fillColor: '#4285F4',
                            border: [true, false, true, true],
                          },
                        ]
                      : []),
                  ];
                } else {
                  return cols?.map((col, i) => {
                    return {
                      text: `${col.prefix || ''}${
                        col.formatValue
                          ? col.formatValue(rowData?.[col.key])
                          : col.key !== 'comment'
                          ? rowData?.[col.key]
                          : rowData?.[col.key]?.length > 22
                          ? rowData?.[col.key]?.slice(0, 22)?.concat('...')
                          : rowData?.[col.key]
                      }`,
                      fontSize: '9',
                      alignment: col.key === 'item_total' ? 'right' : 'left',
                    };
                  });
                }
              }) || []),
            ],
          },
          layout: {
            hLineWidth: function (i, node) {
              return 0.5;
            },
            vLineWidth: function (i, node) {
              return i === 0 || i === node.table.widths.length ? 1 : 0;
            },
            paddingLeft: function (i, node) {
              return 4;
            },
            paddingRight: function (i, node) {
              return 4;
            },
            paddingTop: function (i, node) {
              return 2;
            },
            paddingBottom: function (i, node) {
              return 2;
            },
            fillColor: function (rowIndex, node, columnIndex) {
              return rowIndex % 2 === 0 &&
                rowIndex !== node.table.body.length - 1
                ? '#F2F2F2'
                : null;
            },
          },
        };
      });
    };

    const getSwimlaneSummary = () => {
      let swimlaneSummary =
        // sortArray(budgetData?.budget_swimlane, 'id')
        budgetData?.budget_swimlane?.map((swimlane, index) => {
          const reminder = index % 26;
          const quotient = parseInt(index / 26);
          // const alphaIndex = `${
          //   quotient ? indexToAlpha(quotient) : ''
          // }${indexToAlpha(reminder)}`;

          return {
            rowData: { ...swimlane },
            type: 'swimlane',
            renderMethod: renderSwimlanes,
          };
        }) || [];

      swimlaneSummary = [
        ...swimlaneSummary,
        {
          rowData: { label: 'Production Total', value: budgetTotal },
          type: 'swimlaneFooter',
          renderMethod: renderSwimlaneSummaryFooter,
        },
      ];
      return swimlaneSummary;
    };

    const renderDetailSheet = (key, rowData, className) => {
      return (
        <tr className={className} key={key}>
          {columns
            ?.filter(col => col.key !== 'comment' || includeComments)
            ?.map(col => {
              return (
                <td key={`${col.key}-row-${rowData?.id}-${key}`}>
                  {col.prefix}
                  {col.formatValue
                    ? col.formatValue(rowData?.[col.key])
                    : col.key !== 'comment'
                    ? rowData?.[col.key]
                    : rowData?.[col.key]?.length > 22
                    ? rowData?.[col.key]?.slice(0, 22)?.concat('...')
                    : rowData?.[col.key]}
                </td>
              );
            })}
        </tr>
      );
    };

    const getSwimlaneItemDetails = (
      lineItems,
      swimlaneItems,
      swimlaneKey = '',
      detailsCollection = {}
    ) => {
      swimlaneItems = isArray(swimlaneItems) ? swimlaneItems : [];
      let details;

      // sortArray(lineItems, 'id')
      lineItems?.forEach(item => {
        const collectionKey = `${swimlaneKey}->${item.id}`;
        const collectionItems = [...swimlaneItems, item];

        if (item?.swimlane_item_detail_sheets?.length) {
          details = item?.swimlane_item_detail_sheets;
          detailsCollection[collectionKey] = {
            details,
            collectionItems: collectionItems,
          };
        } else {
          detailsCollection = getSwimlaneItemDetails(
            item?.children,
            collectionItems,
            collectionKey,
            detailsCollection
          );
        }
      });

      return detailsCollection;
    };

    const detailSheetRows = detailSheet => {
      return (
        // sortArray(detailSheet, 'id')
        detailSheet?.map(item => ({
          rowData: item,
          key: `detail-sheet-${item?.id}`,
          type: 'detailed-sheet-row',
        })) || []
      );
    };

    const lineItemDetailsRows = (swimlane, lineItems) => {
      const detailsCollection =
        getSwimlaneItemDetails(lineItems, [], swimlane?.id) || {};

      return Object.keys(detailsCollection)?.reduce(
        (acc, collectionKey) => [
          ...acc,
          ...detailSheetRows(detailsCollection[collectionKey]?.details),
        ],
        []
      );
    };

    const renderSwimlaneDetailsFooter = footerRow => {
      return (
        <tr style={{ border: 'none' }}>
          <td
            style={{ border: '0', background: 'none' }}
            colSpan={footerRow?.colSpan}
          />
          <td
            style={{
              background: '#4285F4',
              color: 'white',
              border: '1px solid black',
              borderRight: '0',
            }}
          >
            {footerRow?.label}
          </td>
          <td
            style={{
              background: '#4285F4',
              color: 'white',
              border: '1px solid black',
              borderLeft: '0',
              borderRight: includeComments ? '0' : '1px',
            }}
          >
            {formatCurrency(footerRow?.value, selectedBudget?.primary_currency)}
          </td>
          {includeComments && (
            <td
              style={{
                background: '#4285F4',
                color: 'white',
                border: '1px solid black',
                borderLeft: '0',
              }}
            />
          )}
        </tr>
      );
    };

    const detailsTableHeader = name => {
      const cols = columns?.filter(
        col => col.key !== 'comment' || includeComments
      );
      return (
        <thead>
          <tr>
            <th
              colSpan={cols.length}
              style={{
                textAlign: 'left',
                color: 'white',
                background: '#4285F4',
              }}
            >
              {name}
            </th>
          </tr>
          <tr>
            {cols?.map(col => (
              <th key={`header-${col.key}`}>{getLabel(col.label)}</th>
            ))}
          </tr>
        </thead>
      );
    };

    const tableRows = swimlane => {
      const detailsRows =
        swimlane?.swimlane_detail_sheets?.length > 0
          ? detailSheetRows(swimlane?.swimlane_detail_sheets)
          : lineItemDetailsRows(swimlane, swimlane?.budget_swimlane_lineitem);

      const cols = columns?.filter(
        col => col.key !== 'comment' || includeComments
      );

      return detailsRows?.length
        ? [
            ...detailsRows.map(item => ({
              ...item,
              renderMethod: (rData, tabIndex) =>
                renderDetailSheet(`${rData.key}-${tabIndex}`, rData, item.type),
            })),
            {
              rowData: {
                label: 'total',
                value: swimlane?.cost,
                colSpan: cols?.length - (includeComments ? 3 : 2),
              },
              type: 'swimlaneFooter',
              renderMethod: renderSwimlaneDetailsFooter,
            },
          ]
        : detailsRows;
    };

    const renderDetailedViewLayout = swimlaneData => {
      const filteredSwimlanes = swimlaneData?.filter(
        swimlane => swimlane.type !== 'swimlaneFooter'
      );
      return filteredSwimlanes?.map((swimlane, index) => {
        const rows = tableRows(swimlane?.rowData);
        return (
          <>
            {rows?.length > 0 && <div className="page-break" />}
            <SwimlanePage
              key={`aicp-table-${swimlane?.id}-${index}`}
              tableHeader={detailsTableHeader(`${swimlane?.rowData?.name}`)}
              printLayoutFooter={printLayoutFooter()}
              printLayoutHeader={printLayoutHeader()}
              tableData={rows}
              tableClassName={'budget-details-sheet-table'}
              tableProps={{ striped: true, bordered: true, hover: true }}
            />
          </>
        );
      });
    };

    const swimlaneData = getSwimlaneSummary();

    return (
      <>
        {budgetData?.budget_swimlane?.length > 0 && (
          <BudgetPDF
            budgetTotal={budgetTotal}
            budgetData={budgetData}
            selectedBudget={selectedBudget}
            budgetType={getBudgetType()}
            productionDetails={
              includeProductionDetails ? productionDetailsConf() : ''
            }
            swimlaneTable={swimlaneConf(swimlaneData)}
            pdfGeneratorRef={pdfGeneratorRef}
          />
        )}
        <div className="budget-data-container" ref={ref}>
          {isLoading && <GridLoader />}
          {budgetData?.budget_swimlane?.length > 0 && (
            <>
              <SwimlanePage
                tableHeader={swimlaneSummaryHeader()}
                tableData={swimlaneData}
                printLayoutFooter={printLayoutFooter()}
                printLayoutHeader={printLayoutHeader()}
                productionDetails={
                  includeProductionDetails ? renderProductionDetails() : ''
                }
                showTitle={true}
                tableClassName={'budget-details-table'}
                tableProps={{ striped: true, bordered: true, hover: true }}
                classNamePrefix={'aicp-'}
              />
              {renderDetailedViewLayout(swimlaneData)}
            </>
          )}
        </div>
      </>
    );
  }
);

AicpBudgetData.displayName = 'AicpBudgetData';

AicpBudgetData.defaultProps = {
  budgetData: [],
};

AicpBudgetData.propTypes = {
  includeComments: PropTypes.bool.isRequired,
  includeProductionDetails: PropTypes.bool.isRequired,
  budgetData: PropTypes.object,
  isLoading: PropTypes.bool.isRequired,
  pdfGeneratorRef: PropTypes.object.isRequired,
  getLabel: PropTypes.func.isRequired,
};

export default AicpBudgetData;
