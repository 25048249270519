import { useDropzone } from 'react-dropzone';
import PropTypes from 'prop-types';
import ProgressBar from 'react-bootstrap/ProgressBar';
import React, { useEffect, useState } from 'react';

import './styles.scss';
import { formatBytes } from 'utils/helper';

const UploadFile = ({
  children,
  className,
  handleUploadFile,
  uploadStatus,
}) => {
  const [dropzoneFiles, setDropZoneFiles] = useState({
    accepted: [],
    rejected: [],
  });

  const maxFileSize = 1024 * 1024 * 3; //3 MB
  const { uploading, percentCompleted } = uploadStatus;

  useEffect(() => {
    setDropZoneFiles({ accepted: [], rejected: [] });
  }, [uploadStatus]);

  const handleFileUpload = files => {
    if (!files.length) {
      return false;
    }

    const formData = new FormData();
    formData.append('file', files[0]);

    handleUploadFile(files[0]);
  };

  const fileSizeValidation = file => {
    if (file?.size > maxFileSize) {
      return {
        code: 'file-size-too-large',
        message: `Max file size allowed ${formatBytes(maxFileSize, 2)}.`,
      };
    }

    return null;
  };

  const {
    acceptedFiles,
    fileRejections,
    getRootProps,
    getInputProps,
    isDragActive,
  } = useDropzone({
    maxFiles: 1,
    accept: {
      'image/png': ['.png', '.jpg', '.jpeg'],
    },
    onDrop: handleFileUpload,
    validator: fileSizeValidation,
  });

  useEffect(() => {
    setDropZoneFiles({
      accepted: acceptedFiles,
      rejected: fileRejections,
    });
  }, [fileRejections, acceptedFiles]);

  const files = dropzoneFiles?.accepted?.map(file => (
    <li key={file.path}>
      {file.path} - {formatBytes(file.size, 2)}
    </li>
  ));

  const fileRejectionItems = dropzoneFiles?.rejected?.map(
    ({ file, errors }) => (
      <li key={file.path}>
        {file.path} - {formatBytes(file.size, 2)} --{' '}
        {errors
          .map(e =>
            e.code === 'file-invalid-type'
              ? 'Upload file type must be PNG or JPG format.'
              : e.message
          )
          ?.join(' ')}
      </li>
    )
  );

  return (
    <div>
      <div
        {...getRootProps({
          className,
        })}
      >
        {uploading && (
          <ProgressBar now={percentCompleted} label={`${percentCompleted}%`} />
        )}
        <input {...getInputProps()} />
        {children}
        {isDragActive && (
          <div className="upload-resume-modal__dropbox__drop-text">
            Drop the files here
          </div>
        )}
        <div className="upload-container__message">
          {fileRejectionItems.length === 1 ? (
            <ul className="upload-container__file-reject">
              {fileRejectionItems}
            </ul>
          ) : (
            files.length === 1 && (
              <ul className="upload-container__file-accept">{files}</ul>
            )
          )}
        </div>
      </div>
    </div>
  );
};

UploadFile.propTypes = {
  children: PropTypes.element.isRequired,
  className: PropTypes.string,
  handleUploadFile: PropTypes.func.isRequired,
  uploadStatus: PropTypes.object,
};

UploadFile.defaultProps = {
  className: '',
  uploadStatus: {},
};
export default UploadFile;
