import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import {
  LoginSocialGoogle,
  LoginSocialApple,
  LoginSocialFacebook,
} from 'reactjs-social-login';

import { ReactComponent as GoogleIcon } from 'assets/images/google-logo.svg';
import { ReactComponent as AppleIcon } from 'assets/images/apple-logo.svg';
import { ReactComponent as FacebookIcon } from 'assets/images/facebook-logo.svg';

import './styles.scss';
import APP_CONFIG from 'config/app-config';

const SocialLogin = ({ type, onLoginSuccess, onLoginError }) => {
  const onLoginSuccessCallback = useCallback(
    provider =>
      ({ data }) => {
        const getToken = {
          google: () => ({ access_token: data?.access_token }),
          facebook: () => ({ access_token: data?.accessToken }),
          apple: () => ({
            access_token: data?.authorization?.id_token,
            code: data?.authorization?.code,
            id_token: data?.authorization?.id_token,
          }),
        };
        // eslint-disable-next-line
        const userData = {
          ...data,
          ...(getToken[provider]?.() || {}),
        };
        // eslint-disable-next-line
        typeof onLoginSuccess === 'function' &&
          onLoginSuccess(provider, userData);
      },
    [onLoginSuccess]
  );

  const onLoginErrorCallback = useCallback(
    provider => error => {
      typeof onLoginError === 'function' && onLoginError(provider, error);
    },
    [onLoginError]
  );

  return (
    <div className="social-login">
      <div className="sign-in-via">
        <div className="sign-in-via__line"></div>
        <div className="sign-in-via__text">Or {type} with</div>
        <div className="sign-in-via__line"></div>
      </div>
      <div className="social-login__options mt-3">
        <LoginSocialGoogle
          isOnlyGetToken
          client_id={APP_CONFIG.socialLoginClientId.google}
          onResolve={onLoginSuccessCallback('google')}
          onReject={onLoginErrorCallback('google')}
          redirect_uri={APP_CONFIG.redirectURI}
          scope={
            'https://www.googleapis.com/auth/userinfo.email https://www.googleapis.com/auth/userinfo.profile'
          }
          className="social-login__options__google"
        >
          <GoogleIcon />
        </LoginSocialGoogle>
        <LoginSocialApple
          client_id={APP_CONFIG.socialLoginClientId.apple}
          scope={'name email'}
          redirect_uri={APP_CONFIG.redirectURI}
          onResolve={onLoginSuccessCallback('apple')}
          onReject={onLoginErrorCallback('apple')}
          className="social-login__options__apple"
        >
          <AppleIcon />
        </LoginSocialApple>
        <LoginSocialFacebook
          appId={APP_CONFIG.socialLoginClientId.facebook}
          redirect_uri={APP_CONFIG.redirectURI}
          onResolve={onLoginSuccessCallback('facebook')}
          onReject={onLoginErrorCallback('facebook')}
          className="social-login__options__facebook"
        >
          <FacebookIcon />
        </LoginSocialFacebook>
      </div>
    </div>
  );
};

SocialLogin.propTypes = {
  type: PropTypes.oneOf(['Sign In', 'Sign Up']).isRequired,
  onLoginSuccess: PropTypes.func.isRequired,
  onLoginError: PropTypes.func,
};

SocialLogin.defaultProps = {
  onLoginError: () => null,
};
export default SocialLogin;
