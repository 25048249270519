import PropTypes from 'prop-types';
import Modal from 'components/Modal';
import React from 'react';

import Button from 'components/Button';
import { ReactComponent as DeleteOutlineIcon } from 'assets/images/delete-outlined-icon.svg';

import './styles.scss';

const DeleteConfirmModal = ({
  confirmText,
  itemName,
  onConfirm,
  onCancel,
  showModal,
  handleOnClose,
  confirmLabel,
  ...rest
}) => {
  const handleConfirm = () => {
    typeof onConfirm === 'function' && onConfirm();
  };

  const handleCancel = () => {
    typeof onCancel === 'function' && onCancel();
  };

  return (
    <Modal
      show={showModal}
      onHide={handleOnClose}
      className="delete-confirm-modal"
      size="sm"
      centered={true}
      {...rest}
    >
      <div className="delete-confirm-modal-body-wrapper">
        <div className="delete-confirm-modal-body-wrapper__image-container">
          <DeleteOutlineIcon className="delete-confirm-modal-body-wrapper__image-container__icon" />
        </div>
        <div className="main-title delete-confirm-modal-body-wrapper__title">
          {confirmText}
        </div>
        <div className="delete-confirm-modal-body-wrapper__text">
          {itemName}
        </div>
        <div className="action-wrapper d-flex flex-justify-center">
          <Button
            label="Cancel"
            className="button-wrapper__cancel-btn"
            onClick={handleCancel}
            variant="grey-outlined"
          />
          <Button
            label={confirmLabel}
            className="button-wrapper__confirm-btn"
            onClick={handleConfirm}
            variant="danger"
          />
        </div>
      </div>
    </Modal>
  );
};

DeleteConfirmModal.propTypes = {
  confirmText: PropTypes.oneOfType([PropTypes.string, PropTypes.element])
    .isRequired,
  itemName: PropTypes.oneOfType([PropTypes.string, PropTypes.element])
    .isRequired,
  onConfirm: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  showModal: PropTypes.bool.isRequired,
  handleOnClose: PropTypes.func.isRequired,
  confirmLabel: PropTypes.string,
};

DeleteConfirmModal.defaultProps = {
  confirmLabel: 'Delete',
};

export default DeleteConfirmModal;
