import React, { useState, useEffect } from 'react';
import Select, { components } from 'react-select';
import PropTypes from 'prop-types';

import { ReactComponent as AddIcon } from 'assets/images/add-icon.svg';

import './styles.scss';

const controlComponent = controlProps => (
  <div className="add-item-input__container">
    <components.Control {...controlProps} />
    <div
      className="add-item-input__container__add-action"
      onClick={controlProps?.selectProps?.onAddItem}
    >
      <AddIcon />
    </div>
  </div>
);

const AddItem = ({ handleAddItem, options }) => {
  const [inputValue, setInputValue] = useState('');
  const [openMenu, setOpenMenu] = useState(false);
  const [selectedOption, setSelectedOption] = useState('');
  const [filteredOptions, setFilteredOptions] = useState([]);
  const [highlightedIndex, setHighlightedIndex] = useState(0);

  useEffect(() => {
    if (options?.length) {
      setFilteredOptions(
        options?.filter(
          item =>
            inputValue &&
            selectedOption !== inputValue &&
            item.label?.toLowerCase()?.includes(inputValue?.toLowerCase())
        ) || []
      );
      setHighlightedIndex(0); // Reset highlighted index when filtered options change
    }
  }, [inputValue, options, selectedOption]);

  useEffect(() => {
    setOpenMenu(filteredOptions?.length ? true : false);
  }, [filteredOptions]);

  const handleKeyDown = event => {
    switch (event.key) {
      case 'Enter':
        if (filteredOptions.length > 0) {
          handleAddItem(
            filteredOptions[highlightedIndex]?.label,
            event.shiftKey
          );
        } else {
          handleAddItem(inputValue, event.shiftKey);
        }
        setInputValue('');
        event.preventDefault();
        break;
      case 'ArrowDown':
        setHighlightedIndex(
          prevIndex => (prevIndex + 1) % filteredOptions.length
        );
        event.preventDefault();
        break;
      case 'ArrowUp':
        setHighlightedIndex(
          prevIndex =>
            (prevIndex - 1 + filteredOptions.length) % filteredOptions.length
        );
        event.preventDefault();
        break;
      case 'Escape':
        handleAddItem(inputValue);
        event.preventDefault();
        break;
      default:
        return '';
    }
  };

  const handleOnChange = item => {
    setSelectedOption(item?.label);
    setInputValue(item?.label);
    setOpenMenu(false);
    handleAddItem(item?.label);
  };

  const addItem = () => {
    handleAddItem(inputValue);
  };

  const handleInputChange = (newValue, action) => {
    action.action === 'input-change' && setInputValue(newValue);
  };

  const customComponents = {
    DropdownIndicator: null,
    Control: controlComponent,
  };

  return (
    <Select
      components={customComponents}
      inputValue={inputValue}
      isClearable={false}
      options={filteredOptions}
      menuIsOpen={openMenu}
      onMenuClose={() => setSelectedOption(inputValue)}
      onFocus={() => setInputValue(selectedOption)}
      onInputChange={handleInputChange}
      onChange={handleOnChange}
      onKeyDown={handleKeyDown}
      placeholder="Enter Category..."
      classNamePrefix="add-item-input"
      className="add-item"
      onAddItem={addItem}
      closeMenuOnSelect={true}
      autoFocus={true}
      value={filteredOptions[highlightedIndex]} // Highlight the current option
    />
  );
};

AddItem.propTypes = {
  handleAddItem: PropTypes.func.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    })
  ),
};

AddItem.defaultProps = {
  options: null,
};

export default AddItem;
