import React, { useState } from 'react';
import {
  useNavigate,
  Outlet,
  Link,
  useLocation,
  matchPath,
} from 'react-router-dom';

import { AccountSettingsContextProvider } from './contexts/AccountSettingsContext';
import { ReactComponent as ArrowBack } from 'assets/images/arrow-back.svg';
import { accountSettingsMenu } from 'utils/constants';
import { ReactComponent as Logout } from 'assets/images/logout.svg';
import { useAppContext } from 'contexts/AppContext';
import { useHomeScreenContext } from 'layouts/HomeScreenLayout/contexts/HomeScreenContext';

import './styles.scss';

const AccountSettingsLayout = () => {
  const { userData } = useAppContext();
  const { logOutModalShow } = useHomeScreenContext();
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const goBack = () => navigate(-1);

  const filterMenuItems = () => {
    return accountSettingsMenu.filter(menuItem => {
      return !(
        menuItem?.hidden || menuItem?.hide_for?.some(item => userData?.[item])
      );
    });
  };

  return (
    <AccountSettingsContextProvider>
      <div className="account-settings">
        <h1 className="account-settings__header d-flex align-items-center">
          <ArrowBack
            onClick={goBack}
            className="account-settings__header__back-icon"
          />
          &nbsp;User Settings
        </h1>
        <section className="account-settings__section row me-0">
          <aside className="col-md-2">
            <div className="account-settings__section__menu">
              {filterMenuItems()?.map(menuItem => {
                const isMatch = matchPath(
                  {
                    path: menuItem.path,
                  },
                  pathname
                );
                const itemClass = 'account-settings__section__menu__item';
                const activeClass = isMatch ? `${itemClass}--active` : '';
                return (
                  <Link
                    key={menuItem.key}
                    className={`${itemClass} ${activeClass}`}
                    to={menuItem.path}
                    replace
                  >
                    <span className={`${itemClass}__icon`}>
                      {menuItem.icon}
                    </span>
                    <span className={`${itemClass}__label`}>
                      {menuItem.label}
                    </span>
                  </Link>
                );
              })}
            </div>
            <div className="account-settings__section__menu">
              <div
                className="account-settings__section__menu__item"
                onClick={logOutModalShow}
              >
                <span className="account-settings__section__menu__item__icon">
                  <Logout />
                </span>
                <span className="account-settings__section__menu__item__label">
                  Log Out
                </span>
              </div>
            </div>
          </aside>
          <main className="account-settings__section__content col-md-10 pe-5">
            <Outlet />
          </main>
        </section>
      </div>
    </AccountSettingsContextProvider>
  );
};

AccountSettingsLayout.propTypes = {};

export default AccountSettingsLayout;
