import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Form, InputGroup } from 'react-bootstrap';

import { ReactComponent as EyeOn } from 'assets/images/visibility.svg';
import { ReactComponent as EyeOff } from 'assets/images/visibility-off.svg';
import { ReactComponent as ErrorIcon } from 'assets/images/error-outline.svg';

import './styles.scss';

const FormPasswordInput = React.forwardRef(
  (
    { label, placeholder, error, inputClassName, labelClassName, ...rest },
    ref
  ) => {
    const [showPassword, setShowPassword] = useState(false);

    const togglePasswordVisibility = () => setShowPassword(!showPassword);

    return (
      <Form.Group className="form-password-group">
        <Form.Label
          className={`d-flex align-item-start form-password-group__label ${labelClassName}`}
        >
          {label}
        </Form.Label>
        <InputGroup>
          <Form.Control
            type={showPassword ? 'text' : 'password'}
            placeholder={placeholder}
            isInvalid={Boolean(error)}
            className={`form-password-group__input ${inputClassName}`}
            ref={ref}
            autoComplete="off"
            {...rest}
          />
          <InputGroup.Text onClick={togglePasswordVisibility}>
            {showPassword ? <EyeOff /> : <EyeOn />}
          </InputGroup.Text>
          {error && (
            <Form.Control.Feedback type="invalid">
              <span className="error-icon">
                <ErrorIcon />
              </span>
              {error}
            </Form.Control.Feedback>
          )}
        </InputGroup>
      </Form.Group>
    );
  }
);

FormPasswordInput.displayName = 'FormPasswordInput';

FormPasswordInput.propTypes = {
  label: PropTypes.string,
  placeholder: PropTypes.string,
  error: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  labelClassName: PropTypes.string,
  inputClassName: PropTypes.string,
};

FormPasswordInput.defaultProps = {
  label: 'Password',
  placeholder: '',
  error: '',
  labelClassName: '',
  inputClassName: '',
};

export default FormPasswordInput;
